var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseField',{attrs:{"element":_vm.element,"model":_vm.internalModel},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"text-left pa-2"},[_c('label',{staticClass:"v-label v-label--active"},[_vm._v(_vm._s(_vm.fieldLabel))])]),_c('div',{staticClass:"wysiwyg"},[_c('Editor',{attrs:{"api-key":_vm.editor_apiKey,"disabled":_vm.field.disabled,"init":{
          height: 250,
          menubar: false,
          plugins: _vm.editor_plugins,
          toolbar: _vm.editor_toolbar,
          force_br_newlines : false,
          force_p_newlines : false,
          forced_root_block : '',
          browser_spellcheck : true,
          content_style: _vm.editor_customParagraphStyle,
          table_toolbar: '',
          toolbar_mode: 'wrap',
          contextmenu: '',
          table_default_attributes: {
            border: '0'
          },
          valid_children: '+body[style|script|*]',
          extended_valid_elements:
            'script[src|async|defer|type|charset],a[href|target|onclick|ontap|onmouseover|onmouseout|onfocus|onkeydown|onkeyup|onsubmit|style|class],button[onclick|ontap|onmouseover|onmouseout|onfocus|onkeydown|onkeyup|onsubmit|style|class],form[onclick|ontap|onmouseover|onmouseout|onload|onfocus|onkeydown|onkeyup|onsubmit|style|class],input[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],textarea[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],select[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],div[onmouseover|onmouseout|onfocus|style|class],span[onmouseover|onmouseout|onfocus|style|class],p[onmouseover|onmouseout|onfocus|style|class],ul[onmouseover|onmouseout|onfocus|style|class],ol[onmouseover|onmouseout|onfocus|style|class],li[onmouseover|onmouseout|onfocus|style|class],iframe[src|frameborder|style|scrolling|class|width|height|name|align|allow]',
        }},model:{value:(_vm.internalModel),callback:function ($$v) {_vm.internalModel=$$v},expression:"internalModel"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }