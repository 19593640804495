import EntityForm from "@/mixins/EntityForm";
import Component, { mixins } from "vue-class-component";

@Component({
    inject: {
        languageService: "languageService",
        eventService: "eventService",
        customFieldService: "customFieldService",
        categoryService: "categoryService",
    },
    watch: {
        "model.fieldType": function () {
            this.updateFieldOptions();
        }
    }
})
export default class CustomFieldForm extends mixins(EntityForm) {
    model = {};
    platformLanguages = [];
    fieldTypes = [];
    categories = [];

    get fieldTypesSelectItems() {
        return this.fieldTypes.map(ft => {
            return {
                value: ft,
                label: ft
            };
        });
    }

    get elements() {
        return [
            {
                id: "code",
                label: this.translations.labels.customField_form_code,
                type: "text",
                required: true,
                rules: "min:4",
                onAction: this.noSpaces,
            },
            {
                id: "fieldType",
                label: this.translations.labels.customField_form_fieldType,
                type: "select",
                required: true,
                items: this.fieldTypesSelectItems
            },
            {
                id: "fieldOptions",
                label: this.translations.labels.customField_form_fieldOptions,
                type: "hidden"
            },
            // if fieldtype == select
            {
                id: "selectAdvancedFieldOptions",
                label: this.translations.labels.customField_form_fieldOptions,
                hide: () => {
                    return !this.hasOptionsList();
                },
                headers: this.selectAdvancedFieldOptions(),
                type: "tabledata",
                canAddRows: true,
                canRemoveRows: true,
                sortable: true,
                resetColumns: true,
                onChange: (val) => {
                    this.updateSelectFieldOptions(val);
                }
            },
            // else if fieldtype == (text or textarea or phone)
            {
                id: "textAdvancedFieldOptions",
                label: this.translations.labels.customField_form_fieldOptions,
                hide: () => {
                    return !this.isText();
                },
                headers: this.textAdvancedFieldOptions(),
                type: "tabledata",
                canAddRows: false,
                canRemoveRows: false,
                sortable: false,
                hideHeader: true,
                resetColumns: true,
                onChange: (val) => {
                    this.updateSimpleFieldOptions(val);
                }
            },
            // else if fieldtype == (number)
            {
                id: "numberAdvancedFieldOptions",
                label: this.translations.labels.customField_form_fieldOptions,
                hide: () => {
                    return !this.isNumber();
                },
                headers: this.numberAdvancedFieldOptions(),
                type: "tabledata",
                canAddRows: false,
                canRemoveRows: false,
                sortable: false,
                hideHeader: true,
                resetColumns: true,
                onChange: (val) => {
                    this.updateSimpleFieldOptions(val);
                }
            },
            // end-if
            {
                id: "displayOrder",
                label: this.translations.labels.customField_form_displayOrder,
                type: "number",
                rules: "gt:0|lte:255",
                required: true,
            },
            {
                id: "optionsDescription",
                type: "paragraph",
                text: this.translations.labels.customField_form_optionsDescription,
                resetColumns: true,
                columns: 2
            },
            {
                id: "isRequired",
                label: this.translations.labels.customField_form_isRequired,
                type: "switch",
            },
            {
                id: "isEditableByUser",
                label: this.translations.labels.customField_form_isEditableByUser,
                type: "switch"
            },
            {
                id: "isHiddenToUser",
                label: this.translations.labels.customField_form_isHiddenToUser,
                type: "switch"
            },
            {
                id: "idCategory",
                label: this.translations.labels.customField_form_category,
                type: "select",
                items: this.categoryList,
                clearable: true,
            },
            {
                id: "localizations",
                type: "localizations",
                header: this.translations.labels.form_localizations,
                languages: this.languages,
                resetColumns: true,
                elements: [
                    {
                        id: "label",
                        label: this.translations.labels.customField_form_label,
                        type: "text",
                        required: true,
                    },
                    {
                        id: "placeholder",
                        label: this.translations.labels.customField_form_placeholder,
                        type: "text",
                    },
                    {
                        id: "fieldHint",
                        label: this.translations.labels.customField_form_fieldHint,
                        type: "textarea",
                    },
                    {
                        id: "fieldOptions",
                        label: this.translations.labels.customField_form_localizedFieldOptions,
                        type: "hidden",
                    },
                ]
            },
        ];
    }

    get categoryList() {
        return this.categories.filter(l => l.isEnabled == true).map((l) => {
            return {
                value: l.id,
                label: l.code,
            };
        });
    }

    get languages() {
        return this.platformLanguages;
    }

    get helpLink() {
        return this.translations.pageHelp.configuration.customField;
    }

    hasOptionsList() {
        const normalizedFieldType = (this.model?.fieldType ?? "").toLowerCase();
        return normalizedFieldType === "select" || normalizedFieldType === "autocomplete";
    }

    isText() {
        const normalizedFieldType = (this.model?.fieldType ?? "").toLowerCase();
        return ["text", "textarea", "phone"].includes(normalizedFieldType);
    }

    isNumber() {
        const normalizedFieldType = (this.model?.fieldType ?? "").toLowerCase();
        return "number" == normalizedFieldType;
    }

    selectAdvancedFieldOptions() {
        if (!this.hasOptionsList()) {
            return [];
        }
        let fieldOptions = [
            { id: "value", label: "Value" }
        ];
        for (const i in this.languages) {
            fieldOptions.push({
                id: this.languages[i].id,
                label: this.languages[i].description
            });
        }
        return fieldOptions;
    }

    textAdvancedFieldOptions() {
        if (!this.isText()) {
            return [];
        }
        return [
            { id: "key", readonly: true },
            {
                id: "value",
                type: (_header, item) => {
                    return ["min", "max"].includes(item.id)
                        ? "number"
                        : "text";
                }
            },
        ];
    }

    numberAdvancedFieldOptions() {
        if (!this.isNumber()) {
            return [];
        }
        return [
            { id: "key", readonly: true },
            {
                id: "value",
                type: (_header, item) => {
                    return ["min", "max"].includes(item.id)
                        ? "number"
                        : "text";
                }
            },
        ];
    }

    prepareTextAdvancedFieldOptions() {
        let parsedFieldOptions = {};
        try {
            parsedFieldOptions = (JSON.parse(this.model.fieldOptions) ?? []);
        } catch (_e) {
            parsedFieldOptions = {};
        }
        return [
            { id: "min", key: "Min Length", value: parsedFieldOptions?.min ?? "" },
            { id: "max", key: "Max Length", value: parsedFieldOptions?.max ?? "" },
            // { id: "pattern", key: "Pattern", value: parsedFieldOptions?.pattern ?? "" },
        ];
    }

    prepareNumberAdvancedFieldOptions() {
        let parsedFieldOptions = {};
        try {
            parsedFieldOptions = (JSON.parse(this.model.fieldOptions) ?? []);
        } catch (_e) {
            parsedFieldOptions = {};
        }
        return [
            { id: "min", key: "Min Value", value: parsedFieldOptions?.min ?? "" },
            { id: "max", key: "Max Value", value: parsedFieldOptions?.max ?? "" },
        ];
    }

    prepareSelectAdvancedFieldOptions() {
        let parsedFieldOptions = {};
        let valuesMaxLength = 0;
        try {
            parsedFieldOptions.value = (JSON.parse(this.model.fieldOptions)?.values ?? []);
        } catch (_e) {
            parsedFieldOptions.value = [];
        }
        valuesMaxLength = Math.max(valuesMaxLength, parsedFieldOptions.value.length);
        for (const i in this.model.localizations) {
            const loc = this.model.localizations[i];
            try {
                parsedFieldOptions[loc.idLanguage] = (JSON.parse(loc.fieldOptions))?.values ?? [];
            } catch (_e) {
                parsedFieldOptions[loc.idLanguage] = [];
            }
            valuesMaxLength = Math.max(valuesMaxLength, parsedFieldOptions[loc.idLanguage].length);
        }
        let advancedFieldOptionsVal = [];
        for (let i = 0; i < valuesMaxLength; ++i) {
            let row = {};
            for (const k in parsedFieldOptions) {
                row[k] = typeof parsedFieldOptions[k][i] !== "undefined"
                    ? parsedFieldOptions[k][i]
                    : "";
            }
            advancedFieldOptionsVal.push(row);
        }
        return advancedFieldOptionsVal;
    }

    resetFieldOptions() {
        this.model.fieldOptions = "{}";
        for (const i in this.model.localizations) {
            this.model.localizations[i].fieldOptions = "{}";
        }
    }

    updateSimpleFieldOptions(val) {
        let rawVal = {};
        for (const i in val) {
            const singleVal = val[i];
            if (singleVal.value) {
                rawVal[singleVal.id] = singleVal.value;
            }
        }
        this.model.fieldOptions = JSON.stringify(rawVal);
        for (const i in this.model.localizations) {
            this.model.localizations[i].fieldOptions = "{}";
        }
    }

    updateSelectFieldOptions(val) {
        let fieldOptionsToStr = {
            value: {
                values: []
            }
        };
        for (const i in this.languages) {
            const lang = this.languages[i];
            fieldOptionsToStr[lang.id] = { values: [] };
        }
        for (const i in val) {
            const row = val[i];
            for (const k in fieldOptionsToStr) {
                fieldOptionsToStr[k].values.push(row[k] ?? "");
            }
        }
        this.model.fieldOptions = JSON.stringify(fieldOptionsToStr.value);
        for (const i in this.model.localizations) {
            this.model.localizations[i].fieldOptions = JSON.stringify(fieldOptionsToStr[this.model.localizations[i].idLanguage]);
        }
    }

    updateFieldOptions() {
        if (this.hasOptionsList()) {
            this.updateSelectFieldOptions(this.model.selectAdvancedFieldOptions);
        } else if (this.isText()) {
            this.updateSimpleFieldOptions(this.model.textAdvancedFieldOptions);
        } else if (this.isNumber()) {
            this.updateSimpleFieldOptions(this.model.numberAdvancedFieldOptions);
        } else {
            this.resetFieldOptions();
        }
    }

    noSpaces(evt) {
        let charCode = evt.charCode;
        if (charCode === 32) evt.preventDefault();
        return true;
    }

    // eslint-disable-next-line no-empty-function
    afterCreate() {
    }

    async created() {
        this.platformLanguages = await this.languageService.list();
        this.fieldTypes = [
            ...await this.customFieldService.fieldTypes(),
            "Autocomplete"
        ];
        this.categories = await this.categoryService.list();
        this.afterCreate();
    }
}
