<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <div class="wrapper-field">
        <v-text-field
          v-model.trim="internalModel"
          :name="field.id"
          :label="fieldLabel"
          :hint="field.hint"
          :persistent-hint="field.persistentHint"
          :placeholder="field.placeholder"
          :disabled="isDisabled()"
          :readonly="isReadonly()"
          :required="isRequired()"
          :copy-to-cliboard="field.copyToCliboard"
          type="text"
          :error="errors.length > 0"
          :error-messages="errors"
          :outlined="!field.readonly"
          @focus="$event.target.select()"
          :append-outer-icon="rightIcon()"
          @click:append-outer="onClickRightIcon"
          @change="onChange"
          :autocomplete="field.autocomplete"
          v-on:keypress="on_action($event)"
        ></v-text-field>

          <v-icon v-if="field.sideAction" right @click="field.sideAction">
            {{ field.sideIcon }}
          </v-icon>
        <div
          v-if="field.copyToClipboard"
          @click="copyToClipboard"
          class="icon-wrapper"
        >
        <v-icon v-if="!feedbackIsVisible" right>
            mdi-content-copy
          </v-icon>
        </div>
        <div v-if="feedbackIsVisible" class="feedback">Copied!</div>
      </div>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";
import SvgIcon from "@/components/dom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class TextField extends BaseField {
  modelFeedbackIsVisible = false;

  on_action(event) {
    if (
      this.field.onAction != undefined &&
      this.field.onAction != null &&
      typeof this.field.onAction === "function"
    )
      this.field.onAction(event);
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.internalModel);

    this.showFeedBack();
  }

  rightIcon() {
    return this.field?.rightIcon ? this.field?.rightIcon : null;
  }

  onClickRightIcon() {
    if (typeof this.field?.onClickRightIcon === "function") {
      this.field.onClickRightIcon(this.internalModel, this.field);
    }
  }

  onChange() {
    if (typeof this.field?.onChange === "function") {
      this.field?.onChange();
    }
  }

  get feedbackIsVisible() {
    return this.modelFeedbackIsVisible;
  }

  showFeedBack() {
    this.modelFeedbackIsVisible = true;

    setTimeout(() => {
      this.modelFeedbackIsVisible = false;
    }, 2000);
  }
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  cursor: pointer;
}

.wrapper-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedback {
  background-color: #8bd499;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
}
</style>