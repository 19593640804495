<template>
  <FieldsetStructure
    :model.sync="internalModel"
    :element="invitationFieldsetElement"
  >
  </FieldsetStructure>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ModelPropagate from "@/mixins/ModelPropagate";
import Translations from "@/mixins/translations";
import Component, { mixins } from "vue-class-component";
import FieldsetStructure from "@/components/form/structure/FieldsetStructure";
import { Utility } from "@/lib/Utility";
import { StrUtility } from "@/lib/StrUtility";
import _env from "@/plugins/env";

@Component({
  components: {
    FieldsetStructure,
  },
  watch: {
    enableCode: function(newVal) {
      if (newVal && !this.internalModel.code) {
        this.autogenerateNewCode();
      }
    },
    "internalModel.code": function(newVal) {
      this.internalModel.code = newVal;
      this.$emit("update:model", this.internalModel);
    }
  }
})
export default class InvitationField extends mixins(
  BaseField,
  ModelPropagate,
  Translations
) {
  internalModel = {};
  enableCode = false;
  vkey = Utility.uid("iel");

  get entityLinkKey() {
    return this.vkey;
  }

  get enableInvitationLink () {
    return this.internalModel?.enableInvitationLink ?? false;
  }

  // model structure:
  //  * visitorlevels (array of ids)
  //  * invitation (enabled boolean + code string)
  get invitationFieldsetElement () {
    return {
      ...this.element,
      legend: this.element?.legend ?? this.translations.labels.invitation_label,
      type: "fieldset",
      group: "default",
      collapsible: true,
      resetColumns: true,
      elements: [
        {
          id: "enableInvitationLink",
          label: this.translations.labels.invitation_enable,
          type: "switch",
          hint: this.translations.labels.invitation_enable_hint,
          readonly: this.element?.readonly ?? false,
          onChange: (val) => {
            this.enableCode = val;
          }
        },
        {
          id: "code",
          label: this.translations.labels.invitation_code,
          type: "text",
          hint: this.translations.labels.invitation_code_hint,
          readonly: this.element?.readonly ?? false,
          rightIcon: "mdi-autorenew",
          onClickRightIcon: () => {
            this.autogenerateNewCode();
          },
          onChange: () => {
            this.generateLink();
          },
          disabled: () => {
            return !this.enableCode;
          },
          rules: { regex: /^[a-zA-Z0-9\-_]{4,}$/ }
        },
        {
          id: "entityLink",
          label: this.translations.labels.invitation_link,
          type: "text",
          readonly: true,
          copyToClipboard: true,
          resetColumns: true,
          hide: () => {
            return !this.enableCode;
          },
          key: this.entityLinkKey
        }
      ]
    };
  }
  autogenerateNewCode() {
    this.internalModel.code = Utility.uid("NVT");
    this.generateLink();
  }
  generateLink() {
    this.internalModel.entityLink = StrUtility.pathJoin(
      _env("VUE_APP_VIRTUAL_PLACE_URL"),
      "invite",
      this.internalModel.code
    );
    // update vkey to force re-render of entityLink field
    this.vkey = Utility.uid("iel");
  }
  created () {
    this.internalModel.code = this.internalModel.code ? this.internalModel.code : null;
    this.internalModel.enableInvitationLink = !!this.internalModel.code;
    this.internalModel.entityLink = "";
    if (this.internalModel.code) {
      this.generateLink();
    }
    this.enableCode = this.internalModel.enableInvitationLink;
  }
}
</script>

<style lang="scss">
</style>