<template>
  <div>
    <!-- nested elements -->
    <FormBodyRepeater
      v-if="groupElements === 'object'"
      :elements="childElements"
      :model.sync="internalModel[structure.id]"
      :columns="elementsColumns"
      :hierarchy="hierarchy">
    </FormBodyRepeater>
    <!-- flat model -->
    <FormBodyRepeater
      v-else
      :elements="childElements"
      :model.sync="internalModel"
      :columns="elementsColumns"
      :hierarchy="hierarchy">
    </FormBodyRepeater>
  </div>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import BaseStructure from "@/components/form/structure/BaseStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "ElementsStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class ElementsStructure extends mixins(BaseStructure, ModelPropagate) {
  decorateAsElements (obj, hierarchy, group = null) {
    if (group === null) {
      group = this.groupElements;
    }
    return {
      type: "elements",
      group: group,
      elements: this.objChildElements(obj),
      columns: this.objColumns(obj),
      hierarchy: hierarchy
    };
  }
}
</script>