<script>
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  watch: {
    internalModel: function (newVal) {
      this.$emit("update:model", newVal);
    },
    model: function (newVal) {
      if (newVal != this.internalModel) this.internalModel = newVal;
    },
  },
  props: {
    model: null,
  },
})
export default class ModelPropagate extends Vue {
  internalModel = null;

  created() {
    this.internalModel = this.model;
  }
}
</script>