<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <v-card class="datalist">
    <v-toolbar v-if="!!structure.header" flat color="primary" dark>
      <v-toolbar-title>{{ structure.header }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        class="pt-2"
        v-if="structure.searchable"
        v-model="needle"
        type="search"
        append-outer-icon="mdi-magnify"
        clearable
        dense
        :placeholder="structure.searchPlaceholder"
        @keyup="applyFilters"
      ></v-text-field>
    </v-toolbar>
    <div v-if="!structure.header && structure.searchable" class="pa-4">
      <v-text-field
        v-model="needle"
        type="search"
        append-outer-icon="mdi-magnify"
        clearable
        :placeholder="structure.searchPlaceholder"
        :label="structure.searchPlaceholder"
        @keyup="applyFilters"
      ></v-text-field>
    </div>
    <v-list two-line subheader v-if="filteredItems.length > 0" :key="vkey">
      <v-list-item-group v-for="(group, groupIndex) in groupItems(filteredItems)" :key="groupIndex">
        <v-divider v-if="group.name && groupIndex > 0"></v-divider>
        <v-subheader v-if="group.name">{{ group.name }}</v-subheader>
        <v-list-item v-for="(item, index) in group.items" :key="index">
          <v-list-item-icon v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.description" :title="item.description">{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="structure.actions && structure.actions.length > 0">
            <div v-for="(action, actionIndex) in structure.actions" :key="actionIndex" v-if="showAction(action, structure)">
              <v-tooltip top v-if="action.tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="() => { action.handler(item, structure, refreshList); }">
                    <v-icon>{{ action.icon }}</v-icon>
                  </v-btn>
                </template>
                <span v-if="action.tooltip">{{ action.tooltip }}</span>
              </v-tooltip>
              <v-btn
                v-else
                color="primary"
                dark
                icon
                @click="() => { action.handler(item, structure, refreshList); }">
                <v-icon>{{ action.icon }}</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-list two-line subheader v-else :key="vkey">
      <v-list-item-group>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ structure.emptyList }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import ElementsStructure from "@/components/form/structure/ElementsStructure";
import Component, { mixins } from "vue-class-component";
import { EventBus } from "@/lib/EventBus";
import { Utility } from "@/lib/Utility";

@Component({
  name: "DatalistStructure",
  props: {
    model: null, // Any type
    element: Object
  },
  watch: {
    model: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.applyFilters();
      }
    }
  }
})
export default class DatalistStructure extends mixins(ElementsStructure, ModelPropagate) {
  needle = null;
  filteredItems = [];
  vkey = Utility.uid("dlt");

  async applyFilters() {
    const searchMinLength = this.structure?.searchMinLength ?? 1;
    const items = this.internalModel[this.structure.id];
    const needle = this?.needle ?? "";

    this.filteredItems = [];

    if (!needle || needle.length < searchMinLength) {
      this.filteredItems = Utility.clone(items);
      return;
    }

    if (typeof this.structure?.applyFilters === "function") {
      this.filteredItems = await this.structure.applyFilters(items, needle);
    } else {
      const rx = new RegExp(needle.replace(/\s/, ".*"), "i");
      this.filteredItems = items.filter((i) => {
        return rx.test(i.title) || rx.test(i.description);
      });
    }
  }

  refreshList() {
    this.filteredItems = [];
    this.$nextTick(() => {
      this.vkey = Utility.uid("dlt");
      this.applyFilters();
    });
  }

  groupItems(items) {
    let groups = [];
    for (let i in items) {
      const item = items[i];
      const groupName = item?.group ?? "";
      const targetGroup = groups.filter(g => { return g.name === groupName; });
      if (targetGroup.length > 0) {
        targetGroup[0].items.push(item);
      } else {
        groups.push({
          name: groupName,
          items: [ item ]
        });
      }
    }
    return groups;
  }

  showAction(action, structure) {
    if (typeof action?.hide === "function") {
      return !action.hide(action, structure);
    }
    return true;
  }

  created() {
    this.applyFilters();
    EventBus.$on("refresh:" + this.element.hierarchy, this.refreshList);
  }

  destroyed() {
    EventBus.$off("refresh:" + this.element.hierarchy, this.refreshList);
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.datalist {
  .v-list-item__subtitle {
    text-align: left;
    font-size: 1.2rem;
  }
  .v-list-item-group > .v-subheader {
    height: 12px;
  }
}
</style>