<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-switch
        v-model="internalModel"
        :name="field.id"
        :label="fieldLabel"
        :disabled="field.disabled"
        :readonly="field.readonly"
        :required="field.required"
        :error="errors.length > 0"
        :error-messages="errors"
        :hint="field.hint"
        :persistent-hint="field.persistentHint"
        inset
      ></v-switch>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component({
  watch: {
    internalModel: function(newVal) {
      if (typeof this.field?.onChange === "function") {
        this.field.onChange(newVal);
      }
    }
  }
})
export default class SwitchField extends BaseField { }
</script>