<script>
import Vue from "vue";
import Component from "vue-class-component";
import { EventBus } from "@/lib/EventBus";

@Component({
  props: {
    element: Object
  }
})
export default class Tinkerable extends Vue {
  PROPAGATE_PROP_DELAY = 2;
  tinker = {};

  tinkered(obj) {
    return {
      ...obj,
      ...this.tinker
    };
  }

  tinkerChanged() {
    // ... does nothing; override it if needed
  }

  tinkerUpdate (options) {
    const optionsKeys = Object.keys(options);
    for (let i in optionsKeys) {
      const key = optionsKeys[i];
      this.tinker[key] = options[key];
    }
    this.tinkerChanged();
  }

  tinkerReset (optionKeys) {
    if (optionKeys.length == 0) {
      this.tinker = {};
    } else {
      for (let i in optionKeys) {
        const optionKey = optionKeys[i];
        if (typeof this.tinker[optionKey] !== "undefined") {
          delete this.tinker[optionKey];
        }
      }
    }
    this.tinkerChanged();
  }

  tinkerReadonly (val) {
    this.tinkerUpdate({
      readonly: !!val
    });
  }

  tinkerDisabled (val) {
    this.tinkerUpdate({
      disabled: !!val
    });
  }

  hierarchyPieces () {
    return this.element?.hierarchy?.split("/")?.filter(p => p !== "") ?? [];
  }

  parentsPaths () {
    const pieces = this.hierarchyPieces();
    let paths = [];
    let lastPath = "";
    for (let i in pieces) {
      const piece = pieces[i];
      paths.push(`${lastPath}/${piece}`);
      lastPath = `${lastPath}/${piece}`;
    }
    return paths;
  }

  ancestor () {
    const pieces = this.parentsPaths();
    return pieces.length > 0 ? pieces[0] : "";
  }

  parent () {
    const pieces = this.hierarchyPieces();
    pieces.pop();
    return pieces.length > 0 ? pieces.join("/") + "/" : "";
  }

  setReadonly(val, hierarchy = null) {
    if (hierarchy === null) {
      hierarchy = this.element?.hierarchy;
    }
    if (hierarchy !== null) {
      setTimeout(() => {
        EventBus.$emit("tinker-readonly:/" + hierarchy, val);
      }, this.PROPAGATE_PROP_DELAY);
    }
  }

  setDisabled(val, hierarchy = null) {
    if (hierarchy === null) {
      hierarchy = this.element?.hierarchy;
    }
    if (hierarchy !== null) {
      setTimeout(() => {
        EventBus.$emit("tinker-disabled:/" + hierarchy, val);
      }, this.PROPAGATE_PROP_DELAY);
    }
  }

  emitHierarchy(eventName, ...args) {
    this.parentsPaths().forEach(path => {
      EventBus.$emit(eventName + ":" + path, ...args);
    });
  }

  onHierarchy(eventName, handler) {
    this.parentsPaths().forEach(path => {
      EventBus.$on(eventName + ":" + path, handler);
    });
  }

  offHierarchy(eventName, handler) {
    this.parentsPaths().forEach(path => {
      EventBus.$off(eventName + ":" + path, handler);
    });
  }

  created () {
    if (this.element?.hierarchy) {
      EventBus.$on("tinker:" + this.element.hierarchy, this.tinkerUpdate);
      EventBus.$on("tinker-reset:" + this.element.hierarchy, this.tinkerReset);

      this.onHierarchy("tinker-readonly", this.tinkerReadonly);
      this.onHierarchy("tinker-disabled", this.tinkerDisabled);
    }
  }

  destroyed () {
    if (this.element?.hierarchy) {
      EventBus.$off("tinker:" + this.element.hierarchy, this.tinkerUpdate);
      EventBus.$off("tinker-reset:" + this.element.hierarchy, this.tinkerReset);

      this.offHierarchy("tinker-readonly", this.tinkerReadonly);
      this.offHierarchy("tinker-disabled", this.tinkerDisabled);
    }
  }
}
</script>