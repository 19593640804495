<template>
  <TextField
    :model="readableModel"
    :name="field.id"
    :element="readonlyField(field)"
    @focus="$event.target.select()">
  </TextField>
</template>

<script>
import TextField from "@/components/form/fields/TextField";
import Component from "vue-class-component";

@Component({
  components: {
    TextField
  }
})
export default class ReadonlyField extends TextField {
  get readableModel () {
    if (this.model === null) {
      return this.model;
    }
    if (Array.isArray(this.model)) {
      return this.model.join(", ");
    } else if (typeof this.model === "object") {
      return this.model.map((v, i) => {
        return `${i}: ${v}`;
      }).join(", ");
    }
    return this.model;
  }
}
</script>