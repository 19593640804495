<template>
  <validation-provider
    :vid="field.hierarchy"
    v-slot="{ errors }"
    :name="field.label"
    :rules="allRules"
    :key="fieldKey"
  >
    <slot v-bind:errors="errors"></slot>
  </validation-provider>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import Tinkerable from "@/mixins/Tinkerable";
import CommonPropsUtils from "@/mixins/CommonPropsUtils";
import Component, { mixins } from "vue-class-component";
import { ValidationProvider } from "vee-validate";
import { Utility } from "@/lib/Utility";

@Component({
  name: "BaseField",
  props: {
    model: null, // Any type
    element: Object
  },
  components: {
    ValidationProvider
  }
})
export default class BaseField extends mixins(ModelPropagate, Tinkerable, CommonPropsUtils) {
  internalModel = null;

  get allRules() {
    const addRequiredRule = this.isRequired() ?? false;
    if (!addRequiredRule) {
      return this.fixRules(this.field.rules) ?? "";
    }
    if (typeof this.field.rules === "undefined") {
      return "required";
    }
    if (typeof this.field.rules === "string") {
      return this.fixRules(this.field.rules) + "|required";
    } else if (typeof this.field.rules === "object") {
      return {
        ...this.fixRules(this.field.rules),
        required: true
      };
    }
    // fallback to basic rules
    return this.field.rules;
  }

  get fieldLabel() {
    if (this.field.readonly) {
      return this.field.label;
    }
    return (this.isRequired())
      ? this.field.label + " *"
      : this.field.label;
  }

  get field() {
    return this.tinkered(this.element);
  }

  get fieldKey() {
    const key = this.field?.key
      ? this.field.key
      : Utility.uid("bas");
    return key;
  }

  /*
   * Fixes custom values; right now these custom values are:
   *  - sibling.@{fieldName}: retrieve a sibling field with the specified fieldName in the hierarchy;
   */
  fixRules(rules) {
    const parentPath = this.parent();
    if (typeof rules === "string") {
      return rules.replace(/sibling\./g, parentPath);
    } else if (typeof rules === "object") {
      for (let i in rules) {
        if (typeof rules[i] === "string") {
          rules[i] = rules[i].replace(/sibling\./g, parentPath);
        }
      }
      return rules;
    }
    return rules;
  }

  tinkerChanged() {
    this.$recompute("field");
  }

  readonlyField(field, extras) {
    return {
      ...field,
      ...extras,
      readonly: true,
      type: "text"
    };
  }

  created() {
    this.tinker.ancestor = this.ancestor();
  }
}
</script>