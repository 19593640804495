<template>
  <div class="entity-form">
    <div class="entity-form__body" v-if="isReady">

      <FormBody
        :form-id="getFormId()"
        :form-title="getFormTitle()"
        :model.sync="model"
        :actions="actions"
        :elements="decoratedElements"
        :columns="fieldColumns"
        :errors.sync="errors"
        :helpLink="helpLink"
        v-on="actionsHandlers">

        <slot></slot>

      </FormBody>
    </div>
  </div>
</template>

<script>
import Tinkerable from "@/mixins/Tinkerable";
import Component, { mixins } from "vue-class-component";
import FormBody from "@/components/form/FormBody";

@Component({
  components: {
    FormBody
  }
})
export default class EntityForm extends mixins(Tinkerable) {
  // data

  isReady = false;
	previousPage = null;
  formId = null;
  errors = [];

  // methods

  get translations() {
    return this.$store.state.translationsStore?.currentLang;
  }

  getFormId () {
    return this.formId !== null
      ? this.formId
      : `form_${this._uid}`;
  }

  getFormTitle () {
    return "";
  }

  get helpLink() {
    return "";
  }

  formatApiErrors(error) {
    let errors = [];
    if (error.code == 422) {
      const fieldsErrorMessages = error.details?.Fields ?? [];
      fieldsErrorMessages.forEach(ef => {
        errors = [
          ...errors,
          ...(ef.Errors.map(e => e.Message) ?? [])
        ];
      });
    } else {
      if(error.description && error.description != "")
        errors.push(error.description);
      else{
        errors.push(error.message);
      }
    }
    return errors;
  }

  get actions() {
    return [];
  }

  get elements() {
    return [];
  }

  get elementsList() {
    return this.decoratedElements;
  }

  get allReadonlyFields() {
    return this.readonlyFields(this.elements);
  }

  get decoratedElements() {
    return this.elements;
  }

  get validationRules() {
    return {};
  }

  get fieldColumns() {
    return 1;
  }

  get fieldWidth() {
    return 12 / this.fieldColumns;
  }

  get actionsHandlers() {
    let actionsHandlers = {};
    this.actions.forEach((a) => {
      const actionName = "action:" + a.id;
      actionsHandlers[actionName] = a.handle;
    });
    return actionsHandlers;
  }

  recursiveReadonlyField (e) {
    if (e === null) {
      return;
    }
    const extra = {};
    const ks = Object.keys(e);
    for (let ki in ks) {
      const k = ks[ki];
      if (Array.isArray(e[k])) {
        extra[k] = [];
        for (let j in e[k]) {
          extra[k][j] = this.recursiveReadonlyField(e[k][j]);
        }
      } else if (typeof e[k] === "object") {
        extra[k] = this.recursiveReadonlyField(e[k]);
      }
    }
    if (typeof e.type !== "undefined" && typeof e.id !== "undefined") {
      extra.readonly = true;
    }
    if (typeof e.rules !== "undefined") {
      delete e.rules;
    }
    if (typeof e.required !== "undefined") {
      delete e.required;
    }
    return {
      ...e,
      ...extra
    };
  }

  readonlyFields(elements) {
    return elements.map(e => {
      return this.recursiveReadonlyField(e);
    });
  }

  insertAt(elements, elementToInsert, index) {
    return [
      ...elements.slice(0, index),
      elementToInsert,
      ...elements.slice(index)
    ];
  }

  prepend(elements, elementToInsert) {
    return this.insertAt(
      elements,
      elementToInsert,
      0
    );
  }

  append(elements, elementToInsert) {
    return this.insertAt(
      elements,
      elementToInsert,
      elements.length
    );
  }

  insertAfter(elements, elementToInsert, id) {
    return this.insertAt(
      elements,
      elementToInsert,
      elements.findIndex(e => e.id == id) + 1
    );
  }

  insertBefore(elements, elementToInsert, id) {
    return this.insertAt(
      elements,
      elementToInsert,
      elements.findIndex(e => e.id == id)
    );
  }

  decorateElement(elements, id, decorations) {
    const index = elements.findIndex(e => e.id == id);
    if (index >= 0) {
      elements[index] = {
        ...elements[index],
        ...decorations
      };
    }
    return elements;
  }

  formTitle() {
    return "";
  }
}
</script>

<style lang="scss" scoped>
.entity-form {
  &__field {
    padding: 5px 25px;
  }
}
</style>