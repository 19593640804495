<template>
  <FieldsetStructure
    :model.sync="internalModel"
    :element="permissionFieldsetElement"
  ></FieldsetStructure>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ModelPropagate from "@/mixins/ModelPropagate";
import Component, { mixins } from "vue-class-component";
import FieldsetStructure from "@/components/form/structure/FieldsetStructure";
import Translations from "@/mixins/translations";

@Component({
  components: {
    FieldsetStructure
  }
})
export default class VisitorLevelsPermissionsField extends mixins(
  BaseField,
  ModelPropagate,
  Translations
) {
  LEVELS_NEEDED_TO_HAVE_FILTERABLE_CHECKBOX_GROUP = 8;
  TOO_MANY_LEVELS = 50;

  get hasTooManyLevels() {
     return this.levels.length > this.TOO_MANY_LEVELS;
  }

  get levels() {
    return this.element?.levels ?? [];
  }

  get fieldId() {
    return this?.element?.id ?? "permissions";
  }

  get permissionFieldsetElement() {
    let levelsField = {
      id: this.fieldId,
      label: this.translations.labels.permissions_levels,
      items: this.levels,
      readonly: this.field.readonly,
      type: "checkboxgroup",
      filterable: (this.levels.length >= this.LEVELS_NEEDED_TO_HAVE_FILTERABLE_CHECKBOX_GROUP)
    };
    if (this.hasTooManyLevels) {
      levelsField = {
        id: this.fieldId,
        label: this.translations.labels.permissions_levels,
        items: this.levels,
        readonly: this.field.readonly,
        type: "autocomplete",
        multiple: true
      };
    }
    return {
      legend: this.element?.legend ?? this.translations.labels.permissions_label,
      type: "fieldset",
      group: "default",
      collapsible: true,
      resetColumns: true,
      elements: [ levelsField ]
    };
  }
}
</script>