<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-subheader class="checkboxgroup-field__label" v-if="fieldLabel">{{ fieldLabel }}</v-subheader>
      <div v-if="field.filterable">
        <v-text-field
          v-model="needle"
          type="search"
          name="visitor-levels-search-needle"
          :label="translations.labels.generic_search"
          append-icon="mdi-magnify"
          @keydown="preventSubmit"
          />
      </div>
      <div v-for="(checkbox, index) in field.items" :key="index">
        <v-checkbox
          v-show="notFiltered(checkbox)"
          dense
          class="ma-0 pa-0"
          v-model="internalModel"
          :label="checkbox.label"
          :value="checkbox.value"
          :name="`${field.id}-${index}`"
          :disabled="field.disabled"
          :readonly="field.readonly"
          :required="field.required"
          :error="(index == (field.items.length - 1)) && errors.length > 0"
          :error-messages="(index == (field.items.length - 1)) ? errors : []"
        ></v-checkbox>
      </div>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";

@Component({
  props: {
    model: Array
  }
})
export default class CheckboxgroupField extends  mixins(
  BaseField,
  Translations
) {
  needle = null;

  preventSubmit(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

  notFiltered(checkbox) {
    if (!this.field.filterable) {
      return true;
    }
    if (!this.needle) {
      return true;
    }
    const rx = new RegExp(`${this.needle}`, "i");
    return rx.test(checkbox.label);
  }

  created () {
    if (!this.internalModel || !Array.isArray(this.internalModel)) {
      this.internalModel = [];
    }
  }
}
</script>

<style lang="scss">
.checkboxgroup-field {
  &__label {
    font-size: 1.1rem;
  }
}
</style>