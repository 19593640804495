<template>
  <div>
    <v-menu
      v-if="!field.readonly"
      v-model="field.openPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <BaseField :element="element" :model="internalModel">
          <template v-slot:default="{ errors }">
            <v-text-field
              v-model="dateWithoutOffset"
              :label="fieldLabel"
              :name="field.id"
              append-outer-icon="mdi-calendar"
              :hint="field.hint"
              :persistent-hint="field.persistentHint"
              :placeholder="field.placeholder"
              :disabled="field.disabled"
              :required="field.required"
              :max="maxDate"
              :min="minDate"
              v-bind="attrs"
              v-on="on"
              :error="errors.length > 0"
              :error-messages="errors"
              readonly
              outlined
              clearable
              @click:clear="internalModel = null"
            ></v-text-field>
          </template>
        </BaseField>
      </template>
      <v-date-picker v-model="dateModel" @input="updateModel">
        <slot>
          <v-container class="datetime-picker__time" fluid align="center">
            <v-row align="center">
              <v-col cols="12" md="2" class="datetime-picker__time-col">
                <v-icon>mdi-clock</v-icon>
              </v-col>
              <v-col cols="12" md="5" class="datetime-picker__time-col">
                <v-select
                  class="datetime-picker__time-slot"
                  v-model="hoursModel"
                  label="HH"
                  placeholder="HH"
                  :disabled="field.disabled || field.readonly"
                  :readonly="field.readonly"
                  :required="field.required"
                  :items="hours"
                  item-text="label"
                  item-value="value"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" class="datetime-picker__time-col">
                <v-select
                  class="datetime-picker__time-slot"
                  v-model="minutesModel"
                  label="MM"
                  placeholder="MM"
                  :disabled="field.disabled || field.readonly"
                  :readonly="field.readonly"
                  :required="field.required"
                  :items="minutes"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </slot>
      </v-date-picker>
    </v-menu>
    <ReadonlyField v-else :model="readableValue" :element="field" />
  </div>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ReadonlyField from "@/components/form/fields/ReadonlyField";
import Component from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  components: {
    ReadonlyField,
  },
  watch: {
    dateModel: function () {
      this.updateModel();
    },
    hoursModel: function () {
      this.updateModel();
    },
    minutesModel: function () {
      this.updateModel();
    },
    internalModel: function () {
      this.$emit("update:model", this.dateWithOffset);
    },
    dateWithOffset: function (newVal) {
      if (newVal) {
        this.dateWithoutOffset = new Date(this.internalModel).toLocaleString(
          window.navigator.language
        );
      } else {
        this.dateWithoutOffset = null;
      }
    },
  },
})
export default class DatetimeField extends BaseField {
  dateModel = null;
  hoursModel = null;
  minutesModel = null;
  hours = [];
  minutes = [];
  dateWithoutOffset = null;

  get readableValue() {
    return this.internalModel == null
      ? null
      : new Date(this.internalModel).toLocaleString(window.navigator.language);
  }

  get maxDate() {
    if (!this.field?.max) {
      return null;
    }
    return new Date(this.field.max).toISOString().substr(0, 10);
  }

  get minDate() {
    if (!this.field?.min) {
      return null;
    }
    return new Date(this.field.min).toISOString().substr(0, 10);
  }

  get dateWithOffset() {
    if (!this.internalModel) {
      return null;
    }
    const date = new Date(this.internalModel);
    const offset = this.getOffset(date);

    return (
      date.getFullYear() +
      "-" +
      this.padDateNumbers(date.getMonth() + 1) +
      "-" +
      this.padDateNumbers(date.getDate()) +
      "T" +
      this.padDateNumbers(date.getHours()) +
      ":" +
      this.padDateNumbers(date.getMinutes()) +
      ":" +
      this.padDateNumbers(date.getSeconds()) +
      offset
    );
  }

  getShortDate(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 + "").padStart(2, "0") +
      "-" +
      (date.getDate() + "").padStart(2, "0")
    );
  }

  updateModel() {
    if (this.dateModel && this.hoursModel && this.minutesModel) {
      const modelDateTime = `${this.dateModel} ${this.hoursModel}:${this.minutesModel}:00`;
      const dateRef = new Date(modelDateTime);
      if (!Utility.isValidDate(dateRef)) {
        this.internalModel = null;
      } else {
        const localOffset = this.getOffset(dateRef);
        this.internalModel = `${modelDateTime}${localOffset}`;
      }
      this.field.openPicker = false;
    }
  }

  padDateNumbers(num) {
    return (num < 10 ? "0" : "") + num;
  }

  getOffset(date) {
    const timeZoneOffset = -date.getTimezoneOffset();
    const dif = timeZoneOffset >= 0 ? "+" : "-";
    return (
      dif +
      this.padDateNumbers(Math.floor(Math.abs(timeZoneOffset) / 60)) +
      ":" +
      this.padDateNumbers(Math.abs(timeZoneOffset) % 60)
    );
  }

  created() {
    for (let i = 0; i < 24; ++i) {
      const hh = ("" + i).padStart(2, "0");
      this.hours.push(hh);
    }
    for (let i = 0; i < 60; i += 5) {
      const mm = ("" + i).padStart(2, "0");
      this.minutes.push(mm);
    }

    if (this.internalModel) {
      const date = new Date(this.internalModel);
      this.internalModel = (this.internalModel ?? "").replace("T", " ");
      this.hoursModel = (date.getHours() + "").padStart(2, "0");
      this.minutesModel = (date.getMinutes() + "").padStart(2, "0");
      this.dateModel = this.getShortDate(date);
    }
  }
}
</script>

<style lang="scss" scoped>
.datetime-picker {
  &__time {
    padding: 0 15px;
    width: 100%;
    max-width: 300px;
  }
  &__time-col {
    padding-top: 0;
  }
}
</style>
<style lang="scss">
.datetime-picker {
  &__time-slot {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>