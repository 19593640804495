<template>
  <TabsStructure
    class="localizations-field"
    v-if="languages.length > 0"
    :model="internalModel"
    :element="localizationTabElement"
    :key="vkey"
  >
    <!--
    <template v-for="(language, index) in languages" v-slot:[tabSlotName(language)]="{ tab }">
      <a :key="index" centered icons-and-text>
        <img
            class="localizations-field__flag"
            :src="`https://flagcdn.com/h20/${language.code}.png`"
            :srcset="`https://flagcdn.com/h40/${language.code}.png 2x, https://flagcdn.com/h60/${language.code}.png 3x`"
            height="20"
            :alt="language.description" />
        <br/>
        {{ tab.label }}
      </a>
    </template>
    -->
  </TabsStructure>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ModelPropagate from "@/mixins/ModelPropagate";
import Component, { mixins } from "vue-class-component";
import TabsStructure from "@/components/form/structure/TabsStructure";
import { Utility } from "@/lib/Utility";

@Component({
  components: {
    TabsStructure,
  },
  watch: {
    languages(newVal, oldVal) {
      const newValStr = JSON.stringify(newVal);
      const oldValStr = JSON.stringify(oldVal);
      if (typeof oldVal !== "undefined" && newValStr !== oldValStr) {
        this.internalModel = [];
        this.vkey = Utility.uid("loc");
      }
    },
  },
})
export default class LocalizationsField extends mixins(
  BaseField,
  ModelPropagate,
) {
  vkey = Utility.uid("loc");

  get languages() {
    return this.element?.languages ?? [];
  }
  get localizationTabElement() {
    const el = {
      ...this.element,
      type: "tabs",
      group: "array",
      tabs: this.languages.map((l) => {
        return {
          id: l.id,
          label: l.description,
          leftIcon: l.icon,
          elements: [
            {
              id: "idLanguage",
              type: "hidden",
              value: l.id,
            },
            ...this.element.elements,
          ],
        };
      }),
    };
    this.fixModel();
    return el;
  }
  tabSlotName(language) {
    return "tab-label-" + language.id;
  }
  fixModel() {
    // id languages
    const langsIds = this.languages.map((l) => l.id);
    // prepare an object useful for sorting, with the order of the
    // languages associated to their position in the array
    let langsIdsWeights = {};
    langsIds.forEach((id, index) => {
      langsIdsWeights[id] = index;
    });
    // search for localization related to languages not currently present
    // and remove them; useful when the list of languages changes in time
    for (let i in this.languages) {
      // eslint-disable-next-line no-unused-vars
      const lang = this.languages[i];
      for (let j = this.internalModel.length - 1; j >= 0; --j) {
        if (!langsIds.includes(this.internalModel[j]?.idLanguage)) {
          this.internalModel.splice(j, 1);
        }
      }
    }
    // retrieve current language ids inside the model
    let currLangIds = [];
    for (let j = this.internalModel.length - 1; j >= 0; --j) {
      if (this.internalModel[j]?.idLanguage) {
        currLangIds.push(this.internalModel[j].idLanguage);
      }
    }
    // if the model is empty or with less elements than the languages array,
    // initialize it with almost-empty objects; almost because we add to them
    // the id language of their tab
    if (this.internalModel.length < langsIds.length) {
      langsIds.forEach((l) => {
        if (!currLangIds.includes(l)) {
          this.internalModel.push({
            idLanguage: l,
          });
        }
      });
    }
    // sort the model so it's synced to the order of the languages;
    // this could happen if both the model and the list of languages has been set as a result
    // from the api; it's probably better to re-order the languages before hand,
    // but still this works as a double check and we'll never have "too many" languages
    this.internalModel.sort((a, b) => {
      return langsIdsWeights[a.idLanguage] - langsIdsWeights[b.idLanguage];
    });
  }
  created() {
    this.fixModel();
  }
}
</script>

<style lang="scss">
  .localizations-field {
    &__flag {
      margin-bottom: 8px;
    }
  }
</style>