<script>
import CustomFieldForm from "@/views/menu/configurations/customFields/customFieldForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    customFieldService: "customFieldService"
  }
})
export default class CustomFieldDetail extends mixins(CustomFieldForm) {
  formId = "detail-custom-field-form";

get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.customFields_detail;
  }

  async afterCreate () {
    this.model = await this.customFieldService.read(this.$route.params.id);
    this.model.selectAdvancedFieldOptions = this.prepareSelectAdvancedFieldOptions();
    this.model.textAdvancedFieldOptions = this.prepareTextAdvancedFieldOptions();
    this.model.numberAdvancedFieldOptions = this.prepareNumberAdvancedFieldOptions();
    this.updateFieldOptions();
    this.isReady = true;
  }
}
</script>
