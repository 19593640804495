<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-text-field
        v-model.number="internalModel"
        type="number"
        :name="field.id"
        :label="fieldLabel"
        :hint="field.hint"
        :persistent-hint="field.persistentHint"
        :placeholder="field.placeholder"
        :disabled="field.disabled"
        :readonly="field.readonly"
        :required="field.required"
        :error="errors.length > 0"
        :error-messages="errors"
        :outlined="!field.readonly"
        :step="fieldStep"
        :min="field.min"
        :max="field.max"
        @focus="$event.target.select()"
        :append-outer-icon="rightIcon()"
        @click:append-outer="onClickRightIcon"
        v-on:keypress="checkValue($event)"
      ></v-text-field>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component()
export default class NumberField extends BaseField {
  get fieldStep() {
    return this.field.step ?? 1;
  }

  checkValue(evt) {
    let charCode = evt.charCode;
    // ignore all non-digit characters
    if (charCode > 31 && charCode != 46 && (charCode < 48 || charCode > 57))
      evt.preventDefault();
    return true;
  }

  rightIcon() {
    return this.field?.rightIcon ? this.field?.rightIcon : null;
  }

  onClickRightIcon() {
    if (this.isDisabled() || this.isReadonly()) {
      return;
    }
    if (typeof this.field?.onClickRightIcon === "function") {
      this.field.onClickRightIcon(this.internalModel, this.field);
    }
  }
}
</script>