<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <!-- TODO: change the textarea with something like codemirror -->
      <v-textarea
        v-model.trim="internalModel"
        :name="field.id"
        :label="fieldLabel"
        :hint="field.hint"
        :persistent-hint="field.persistentHint"
        :placeholder="field.placeholder"
        :disabled="field.disabled"
        :readonly="field.readonly"
        :required="field.required"
        :error="errors.length > 0"
        :error-messages="errors"
        :outlined="!field.readonly"
        @focus="$event.target.select()"
      ></v-textarea>
      <v-dialog
        v-model="preview"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on">
            {{ translations.labels.html_preview }}
          </v-btn>
        </template>

        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{
              translations.labels.html_preview
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="preview = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card class="htmlfield-preview-container" align="center">
            <v-btn-toggle class="htmlfield__preview-size" v-model="size">
              <v-btn
                value="desktop"
                :title="translations.labels.html_preview_sizeDesktop"
              >
                <v-icon>mdi-monitor</v-icon>
              </v-btn>
              <v-btn
                value="tablet"
                :title="translations.labels.html_preview_sizeTablet"
              >
                <v-icon>mdi-tablet</v-icon>
              </v-btn>
              <v-btn
                value="mobile"
                :title="translations.labels.html_preview_sizeMobile"
              >
                <v-icon>mdi-cellphone</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-card color="grey lighten-2">
              <v-card :width="sizeWidth" :height="sizeHeight" v-if="preview">
                <iframe class="htmlfield__preview" :srcdoc="previewContent" />
                <!-- we add an overlay so link cannot be cliked; this is to avoid accidentally leaving the cms while compiling a template -->
                <div class="htmlfield__overlay">&nbsp;</div>
              </v-card>
            </v-card>
          </v-card>
        </v-card>
      </v-dialog>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Translations from "@/mixins/translations";
import Component, { mixins } from "vue-class-component";

@Component()
export default class HtmlField extends mixins(BaseField, Translations) {
  preview = false;
  size = "desktop";

  get previewContent() {
    if (typeof this.field?.previewContentHandler === "function") {
      return this.field?.previewContentHandler(this.internalModel);
    }
    return this.internalModel;
  }

  get sizeWidth() {
    switch (this.size) {
      case "desktop":
        return "100%";
      case "tablet":
        return "1024px";
      case "mobile":
        return "360px";
    }
    return window.innerWidth + "px";
  }

  get sizeHeight() {
    switch (this.size) {
      case "desktop":
        return window.innerHeight + "px";
      case "tablet":
        return "768px";
      case "mobile":
        return "640px";
    }
    return window.innerHeight + "px";
  }

  get previewHeight() {
    return window.innerHeight + "px";
  }

  get previewWidth() {
    return window.innerWidth + "px";
  }
}
</script>

<style lang="scss" scoped>
.htmlfield {
  &__preview-container {
    padding: 15px;
  }
  &__preview {
    width: 100%;
    height: 100%;
    border: none;
    background: #fff;
  }
  &__preview-size {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &__overlay {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>