<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-textarea
        v-model.trim="internalModel"
        :name="field.id"
        :label="fieldLabel"
        :hint="field.hint"
        :persistent-hint="field.persistentHint"
        :placeholder="field.placeholder"
        :disabled="field.disabled"
        :readonly="field.readonly"
        :required="field.required"
        :error="errors.length > 0"
        :error-messages="errors"
        auto-grow
        :outlined="!field.readonly"
        @focus="$event.target.select()"
      ></v-textarea>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component()
export default class TextareaField extends BaseField {}
</script>