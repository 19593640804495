<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-file-input
        v-if="!field.readonly"
        v-model="selectedFile"
        :prepend-icon="fieldIcon"
        :name="field.id"
        :label="fieldLabel"
        :hint="field.hint"
        :persistent-hint="field.persistentHint"
        :placeholder="field.placeholder"
        :disabled="field.disabled"
        :readonly="field.readonly"
        :required="field.required"
        :error="errors.length > 0"
        :error-messages="errors"
        :outlined="!field.readonly"
        :accept="accept"
        truncate-length="100"
        @change="onFileSelected"
      ></v-file-input>
      <ReadonlyField v-else :model="filename" :element="field" />
      <div v-if="showPreview()" class="file-field__preview">
        <v-card v-if="isImage" class="pa-2">
          <img
            v-if="fileSrc"
            :src="fileSrc"
            :title="filename"
            class="file-field__preview-img"
          />
        </v-card>
        <a
          v-else-if="fileSrc"
          :href="fileSrc"
          target="_blank"
          :download="filename"
          >{{ filename }}</a
        >
      </div>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";
import ReadonlyField from "@/components/form/fields/ReadonlyField";
import { EventBus } from "@/lib/EventBus";
import _env from "@/plugins/env";

@Component({
  components: {
    ReadonlyField,
  },
  inject: {
    fileUploadService: "fileUploadService",
  },
})
export default class FileField extends BaseField {
  selectedFile = null;
  filename = null;
  fileSrc = null;

  get fieldIcon() {
    if (this.field?.icon) {
      return this.field?.icon;
    }
    return "mdi-paperclip";
  }

  get accept() {
    return this.field.accept ?? "*/*";
  }

  get isImage() {
    return /(\.png|\.jpg|\.jpeg|\.svg|\.ico)$/gi.test(this.filename);
  }

  showPreview() {
    return this.filename !== null && this.selectedFile !== null;
  }

  async onFileSelected() {
    if (typeof this.selectedFile === "undefined") {
      this.filename = null;
      this.internalModel = null;
      return;
    }
    try {
      const uploadedFile = await this.fileUploadService.upload(
        this.selectedFile
      );
      this.internalModel = uploadedFile.file;

      let reader = new FileReader();
      // Add an event listener to the reader when the file has been loaded
      reader.addEventListener(
        "load",
        () => {
          this.fileSrc = reader.result;
          this.filename = uploadedFile.file;
        },
        false
      );

      if (this.selectedFile) {
        //read the file in and upon completion would file a 'load' event
        reader.readAsDataURL(this.selectedFile);
      }
    } catch (e) {
      EventBus.$emit(
        "error:" + this.ancestor(),
        this.field.hierarchy,
        e.description,
        {
          code: e.code,
          details: e.details,
        }
      );
    }
  }

  async getFileFromUrl(url, name, defaultType = "image/jpeg") {
    if (url === "") {
      return null;
    }
    let thumbUrl = this.getThumbUrl(url);

    let response = await fetch(thumbUrl);
    if (!response || response.status > 400)
      response = await fetch(url);

    const data = await response.blob();
    let fileName = url.substring(url.lastIndexOf("/") + 1);
    return new File([data], fileName, {
      type: response.headers.get("content-type") || defaultType,
    });
  }

  getThumbUrl(url) {
    let thumb = ".thumb";
    let extensionStartIndex = url.lastIndexOf(".");
    if (extensionStartIndex == -1)
      return url + thumb;
    else
      return url.substring(0, extensionStartIndex) + thumb + url.substring(extensionStartIndex);
  }

  getFileUrl(path) {
    if (!path) {
      return "";
    }
    if (path.startsWith("http")) {
      return path;
    }
    if (path !== "/Links/") {
      return _env("VUE_APP_API_BASE_URL") + "/Files/" + path;
    }
    return path;
  }

  async created() {
    if (this.internalModel !== null) {
      const fileUrl = this.getFileUrl(this.internalModel);
      this.filename = this.internalModel;
      const file = await this.getFileFromUrl(fileUrl);
      if (file && file.size > 0) {
        this.selectedFile = file;
        this.fileSrc = await this.getFileUrl(this.filename);
      }
    }
  }
}
</script>

<style lang="scss">
.file-field {
  &__preview {
    max-width: 100%;
  }
  &__preview-img {
    max-width: 100%;
  }
}
</style>