<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{}">
      <div class="text-left pa-2">
        <label class="v-label v-label--active">{{ fieldLabel }}</label>
      </div>
      <div class="wysiwyg">
        <!--
          To enable other tag's attribute/action/event just add it in "extended_valid_elements" like
            pattern: tag[attribute|attribute|event|attribute="defaultValue"],tag[attribute]
            eg. a[href|onclick|target="_blank"]
        -->
        <Editor
          :api-key="editor_apiKey"
          v-model="internalModel"
          :disabled="field.disabled"
          :init="{
            height: 250,
            menubar: false,
            plugins: editor_plugins,
            toolbar: editor_toolbar,
            force_br_newlines : false,
            force_p_newlines : false,
            forced_root_block : '',
            browser_spellcheck : true,
            content_style: editor_customParagraphStyle,
            table_toolbar: '',
            toolbar_mode: 'wrap',
            contextmenu: '',
            table_default_attributes: {
              border: '0'
            },
            valid_children: '+body[style|script|*]',
            extended_valid_elements:
              'script[src|async|defer|type|charset],a[href|target|onclick|ontap|onmouseover|onmouseout|onfocus|onkeydown|onkeyup|onsubmit|style|class],button[onclick|ontap|onmouseover|onmouseout|onfocus|onkeydown|onkeyup|onsubmit|style|class],form[onclick|ontap|onmouseover|onmouseout|onload|onfocus|onkeydown|onkeyup|onsubmit|style|class],input[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],textarea[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],select[onclick|ontap|onmouseover|onmouseout|onload|onfocus|oninput|onchange|onkeydown|onkeyup|onsubmit|style|class],div[onmouseover|onmouseout|onfocus|style|class],span[onmouseover|onmouseout|onfocus|style|class],p[onmouseover|onmouseout|onfocus|style|class],ul[onmouseover|onmouseout|onfocus|style|class],ol[onmouseover|onmouseout|onfocus|style|class],li[onmouseover|onmouseout|onfocus|style|class],iframe[src|frameborder|style|scrolling|class|width|height|name|align|allow]',
          }"
        />
      </div>
    </template>
  </BaseField>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component({
  components: {
    Editor,
  },
  watch: {},
})
export default class WysiwygField extends BaseField {
  editor_toolbar =
    "searchreplace | preview | selectall | undo redo | fontselect | fontsizeselect | formatselect | removeformat bold italic underline | forecolor backcolor lineheight | alignleft aligncenter alignright alignjustify | indent outdent | bullist numlist | link unlink table code image";
  editor_plugins = ["lists link image paste help wordcount code image table preview searchreplace"];
  editor_apiKey = "qm6mgdcuhopexwq4zbz60mewgy9mvgiixfq62smxljqcv19m";
  editor_customParagraphStyle = "p{margin:0};";

  get isEditable() {
    return !this.field.readonly && !this.field.disabled;
  }
}
</script>

<style lang="scss" scoped>
.wysiwyg {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  &__menubar-button-divider {
    height: 12px;
    border-width: 1px;
  }

  &__menubar-button-wrapper {
    display: inline-block;

    &--hidden {
      visibility: hidden;
    }
  }

  .editor__content {
    padding: 5px;
  }
}
</style>
<style lang="scss">
.wysiwyg {
  .menubar {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .menubar__button {
      border: 1px solid rgba(0, 0, 0, 0.12);

      &:hover,
      &.is-active {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .editor__content .ProseMirror {
    min-height: 200px;
    text-align: left;
    padding: 5px;
    font-size: 1.2rem;
  }
}

.tox-tinymce {
  min-height: 400px;
}

p{
  margin: 0;
}
</style>