<!-- eslint-disable vue/no-unused-vars -->
<template>
  <BaseField :element="element" :model="internalModel">
      <template v-slot:default="{ errors }">
        <label class="table-data-field__label">{{ fieldLabel }}</label>
        <br class="table-data-field__clear" />
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :items-per-page="-1"
          :hide-default-header="element.hideHeader"
          :data-readonly-mymy="element.readonly"
          v-sortable="sortableOptions"
          @sorted="sortElements"
          hide-default-footer
          dense
        >
          <template
            v-for="(header, index) in tableHeaders"
            v-slot:[`item.${header.value}`]="item"
          >
            <span :key="index">
              <strong
                class="table-data-field__readonly-cell"
                v-if="header.readonly"
                >{{ internalModel[item.item.__index][header.value] }}</strong
              >
              <v-text-field
                v-else
                :rules="header.rules"
                :type="itemType(header, item.item)"
                :name="header.id"
                :label="header.label"
                :placeholder="itemPlaceholder(header)"
                :hint="itemHint(header)"
                v-model.trim="internalModel[item.item.__index][header.value]"
                @change="emitChange"
              />
            </span>
          </template>
          <template v-slot:item.tdf__delete="item">
            <v-btn fab dark small @click="removeRow(item)">
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.tdf__sort_handle="item">
            <v-icon class="table-data-field__handle">
              mdi-drag-horizontal
            </v-icon>
          </template>
        </v-data-table>
        <v-btn dark v-if="element.canAddRows && !isReadonly()" @click="addRow"
          >+</v-btn
        >
        <br class="table-data-field__clear" />
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";
import { Utility } from "@/lib/Utility";
import Sortable from "sortablejs";

@Component({
  components: {
    Sortable,
  },
  directives: {
    sortable: {
      bind(el, binding, vnode) {
        if (binding.value) {
          const options = {
            animation: 150,
            onUpdate: function (event) {
              vnode.child.$emit("sorted", event);
            },
          };
          Sortable.create(el.getElementsByTagName("tbody")[0], options);
        }
      },
    },
  },
})
export default class TabledataField extends BaseField {

  get tableHeaders() {
    let tableHeaders = (this.element?.headers ?? []).map((h) => {
      return {
        ...h,
        text: h?.label ?? h.id,
        align: h?.align ?? "start",
        sortable: false,
        readonly: h.readonly || this.isReadonly(),
        value: h.id,
      };
    });
    if (this.element.canRemoveRows && !this.isReadonly()) {
      tableHeaders.push({
        text: "",
        align: "end",
        sortable: false,
        value: "tdf__delete",
      });
    }
    if (!!this.element?.sortable && !this.isReadonly()) {
      tableHeaders.unshift({
        text: "",
        align: "start",
        sortable: false,
        value: "tdf__sort_handle",
      });
    }
    return tableHeaders;
  }

  get sortableOptions() {
    if (!this.element?.sortable || this.isReadonly()) {
      return false;
    }
    return {
      handle: ".table-data-field__handle",
    };
  }

  get items() {
    return (this.internalModel ?? []).map((r, i) => {
      return {
        ...r,
        __index: i,
      };
    });
  }

  itemType(header, item) {
    if (typeof header?.type == "function") {
      return header?.type(header, item);
    }
    if (["text", "textarea", "number"].includes(header?.type)) {
      return header.type;
    }
    return "text";
  }

  itemPlaceholder(header) {
    return header?.placeholder ?? "";
  }

  itemHint(header) {
    return header?.hint ?? "";
  }

  createRowRecord(values = {}) {
    let row = {};
    for (const i in this.element.headers) {
      const header = this.element.headers[i];
      row[header.id] =
        typeof values[header.id] !== "undefined" ? values[header.id] : null;
    }
    return row;
  }

  addRow(values = {}) {
    this.internalModel.push(this.createRowRecord(values));
    this.emitChange();
  }

  removeRow(item) {
    const index = item.item.__index;
    this.internalModel.splice(index, 1);
    this.emitChange();
  }

  emitChange() {
    if (typeof this.element.onChange === "function") {
      this.element.onChange(this.internalModel);
    }
  }

  emitSortChange(emitModel) {
    if (typeof this.element.onChange === "function") {
      this.element.onChange(emitModel);
    }
  }

  async sortElements(event) {
    let cloned = Utility.clone(this.internalModel);
    cloned = Utility.moveElementInArray(cloned, event.oldIndex, event.newIndex);
    this.internalModel = [];
    this.emitSortChange(cloned);
    await this.$nextTick();
    this.internalModel = cloned;
  }

  created() {
    if (!this.internalModel) {
      this.internalModel = [];
    }
    for (const i in this.internalModel) {
      this.createRowRecord(this.internalModel[i]);
    }
  }
}
</script>

<style lang="scss" scoped>
.table-data-field {
  &__label {
    font-size: 16px;
    font-weight: 700;
    float: left;
  }
  &__clear {
    content: "";
    clear: both;
    display: table;
    margin-bottom: 15px;
  }
  &__readonly-cell {
    text-decoration: underline;
  }
  &__handle {
    cursor: ns-resize;
  }
}
</style>