<template>
  <div class="paragraph-struct">
    <p v-html="paragraphText"></p>
  </div>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import BaseStructure from "@/components/form/structure/BaseStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "ParagraphStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class ParagraphStructure extends mixins(BaseStructure, ModelPropagate) {
  /**
   * The `text` value of element contains the text to show.
   * This text will be manipulated as follows:
   *  * newline will be transformed in `<br/>`;
   *  * text in between `[*` and `*]` will be renderd as italic (i.e.: `[*this text will be italic*]`);
   *  * text in between `[**` and `**]` will be rendered as bold (i.e.: `[**this text will be bold**]`);
   *  * text in between `[***` and `***]` will be rendered both as bold and italic (i.e.: `[***this text will be both bold and italic***]`);
   *  * text in between `[_` and `_]` will be underlined (i.e.: `[_this text will be underlined_]`);
   *  * to render a link, write text in between `[~` and `~]`, splitting the href and text with another `~` (i.e.: `[~switchup.it~Switchup~]` will become `<a target="_blank" href="switchup.it">Switchup</a>`);
   */
  get paragraphText() {
    return (this.element?.text + "")
            // new lines
            .replace(/(\n|\r|\n\r|\r\n)/g, "<br/>")
            // tabs
            .replace(/\t/g, "&emsp;&emsp;")
            // bold and italic
            .replace(/(?<=\[\*\*\*)(.+?)(?=\*\*\*\])/g, "<strong><em>$1</em></strong>")
            .replace(/(\[\*\*\*|\*\*\*\])/g, "")
            // bold
            .replace(/(?<=\[\*\*)(.+?)(?=\*\*\])/g, "<strong>$1</strong>")
            .replace(/(\[\*\*|\*\*\])/g, "")
            // italic
            .replace(/(?<=\[\*)(.+?)(?=\*\])/g, "<em>$1</em>")
            .replace(/(\[\*|\*\])/g, "")
            // underline
            .replace(/(?<=\[_)(.+?)(?=_\])/g, "<u>$1</u>")
            .replace(/(\[_|_\])/g, "")
            // link
            .replace(/(?<=\[~)(.+?)~(.+?)(?=~\])/g, "<a target=\"_blank\" href=\"$1\">$2</a>")
            .replace(/(\[~|~\])/g, "");
  }
}
</script>

<style lang="scss" scoped>
.paragraph-struct {
  text-align: left;
  font-size: 14px;
  padding-left: 15px;
}
</style>