<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="open"
    scrollable
    :fullscreen="structure.fullscreen"
    :persistent="structure.persistent"
    class="dialog"
    max-width="40%"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="text-center">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="isDisabled()"
        >{{ structure.buttonText }}</v-btn>
      </div>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          dark
          v-if="structure.header"
        >
          <v-toolbar-title>{{ structure.header }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="structure.withCloseBtn" icon>
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- nested elements -->
          <ElementsStructure
            v-if="structure.elements && structure.elements.length > 0 && groupElements === 'object'"
            :model.sync="internalModel[structure.id]"
            :element="decorateAsElements(structure, hierarchy, 'default')">
          </ElementsStructure>
          <!-- flat model -->
          <ElementsStructure
            v-else-if="structure.elements && structure.elements.length > 0"
            :model.sync="internalModel"
            :element="decorateAsElements(structure, hierarchy)">
          </ElementsStructure>
        </v-card-text>
        <v-card-actions v-if="actions" class="justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            text
            color="blue darken-1"
            @click="action.handler"
            :disabled="disabledAction(action)">
            <v-icon v-if="structure.leftIcon" left>{{ structure.leftIcon }}</v-icon>
            {{ action.label }}
            <v-icon v-if="structure.rightIcon" right>{{ structure.rightIcon }}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import Translations from "@/mixins/translations";
import ElementsStructure from "@/components/form/structure/ElementsStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "DialogStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class DialogStructure extends mixins(ElementsStructure, ModelPropagate, Translations) {
  open = false;

  get actions() {
    let actions = [];
    if (this.structure.withCloseBtn) {
      actions.push({
        label: this.translations.labels.dialog_close,
        handler: () => { this.open = false; }
      });
    }
    const dialogActions = this.structure?.actions ?? [];
    return [
      ...actions,
      ...dialogActions
    ];
  }

  disabledAction(action) {
    if (typeof action?.disabled === "function") {
      return action.disabled();
    }
    return false;
  }

  closeDialog() {
    this.open = false;
  }
}
</script>

<style lang="scss" scoped></style>