<!-- eslint-disable vue/no-unused-vars -->
<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-btn
        dark
        :color="fieldColor"
        :disabled="isDisabled()"
        @click="field.handler">
        <v-icon v-if="field.leftIcon" left dark>{{ field.leftIcon }}</v-icon>
        {{ field.label }}
        <v-icon v-if="field.rightIcon" right dark>{{ field.rightIcon }}</v-icon>
      </v-btn>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component()
export default class ButtonField extends BaseField {
  get fieldColor() {
    return this.field?.color ?? "primary";
  }
}
</script>