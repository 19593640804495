<template>
  <div class="form-element">
    <component v-if="isFieldComponent && transcludeModel" :is="fieldComponent" :model.sync="internalModel" :element="element"></component>
    <component v-else-if="isFieldComponent" :is="fieldComponent" :model.sync="internalModel[element.id]" :element="element"></component>
    <component v-else-if="isStructureComponent" :is="structureComponent" :model.sync="internalModel" :element="element"></component>
    <slot :name="element.id"></slot>
  </div>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import Component, { mixins } from "vue-class-component";
import { StrUtility } from "@/lib/StrUtility";
import * as Fields from "@/components/form/fields/index";
import * as Structure from "@/components/form/structure/index";

@Component({
  name: "FormElement",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class FormElement extends mixins(ModelPropagate) {
  internalModel = null;

  get transcludeModel() {
    return !!this?.element?.transcludeModel;
  }

  get fieldComponent() {
    if (!this?.element?.type) {
      return null;
    }
    let fieldName = StrUtility.pascalCase(this.element.type);
    if (typeof Fields[fieldName] !== "undefined") {
      return Fields[fieldName];
    }
    fieldName += "Field";
    if (typeof Fields[fieldName] !== "undefined") {
      return Fields[fieldName];
    }
    return null;
  }

  get isFieldComponent() {
    return this.fieldComponent !== null;
  }

  get structureComponent() {
    if (!this?.element?.type) {
      return null;
    }
    let elementName = StrUtility.pascalCase(this.element.type);
    if (typeof Structure[elementName] !== "undefined") {
      return Structure[elementName];
    }
    elementName += "Structure";
    if (typeof Structure[elementName] !== "undefined") {
      return Structure[elementName];
    }
    return null;
  }

  get isStructureComponent() {
    return this.structureComponent !== null;
  }

}
</script>

<style lang="scss" scoped>
.form-element {
  padding: 5px 25px;
  width: 100%;
  box-sizing: border-box;
}
</style>