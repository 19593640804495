<template>
  <div class="divider">
    <span class="divider__text" v-if="dividerText">{{ dividerText }}</span>
  </div>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import BaseStructure from "@/components/form/structure/BaseStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "DividerStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class DividerStructure extends mixins(BaseStructure, ModelPropagate) {
  get dividerText() {
    return (this.element?.text + "");
  }
}
</script>

<style lang="scss" scoped>
.divider {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;

  &::before,
  &::after {
    content: '';
    border-bottom: 1px solid #000;
  }

  &__text {
    font-size: 16px;
  }

  &:not(:empty)::before {
    flex: 1;
    margin-right: .5em;
  }

  &:not(:empty)::after {
    margin-left: .5em;
    flex: 30;
  }
}
</style>