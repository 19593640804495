<template>
  <div>
    <v-dialog
      transition="dialog-top-transition"
      v-model="open"
      persistent
      content-class="media-field__dialog"
    >
      <v-card style="height:100%">
        <v-card-text class="pa-0" style="height: calc(100% - 52px)">
          <MediaLib
            :multiple="false"
            :initially-selected="initiallySelected"
            :initial-folder="libInitialFolder"
            :accept="accept"
            :missing-file="missingFromLib"
            :is-dialog="true"
            propagate-dbl-click-file
            @medialib:update-selected="updateSelected"
            @medialib:dblclickfile="updateDblClickSelected"
          />
        </v-card-text>
        <v-card-actions class="justify-end media-field__dialog-actions">
          <v-spacer></v-spacer>
          <v-btn @click="closeMediaLib">{{
            translations.labels.mediaField_cancel
          }}</v-btn>
          <v-btn
            :disabled="selectedAtLeastOne"
            color="primary"
            @click="select"
            >{{ translations.labels.mediaField_select }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BaseField :element="element" :model="internalModel">
      <template v-slot:default="{ errors }">
        <v-text-field
          v-if="!field.readonly"
          v-model="internalModel"
          :prepend-icon="fieldIcon"
          :name="field.id"
          :label="fieldLabel"
          :hint="field.hint"
          :persistent-hint="field.persistentHint"
          :placeholder="field.placeholder"
          :disabled="field.disabled || open"
          :readonly="field.readonly || open"
          :required="field.required"
          :clearable="!field.required"
          :error="errors.length > 0"
          :error-messages="errors"
          :outlined="!field.readonly"
          @click:clear="clearField"
          @focus="openMediaLib"
        ></v-text-field>
        <ReadonlyField v-else :model="filename" :element="field" />
        <div v-if="showPreview" class="media-field__preview">
          <v-card v-if="isImage" class="pa-2 justify-center">
            <v-img
              v-if="selectedFile"
              max-width="128px"
              max-height="128px"
              contain
              :src="selectedFile"
              :title="filename"
              class="media-field__preview-img"
            />
          </v-card>
          <a
            v-else-if="selectedFile"
            :href="selectedFile"
            target="_blank"
            :download="filename"
            >{{ filename }}</a
          >
        </div>
      </template>
    </BaseField>
  </div>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component, { mixins } from "vue-class-component";
import Translations from "@/mixins/translations";
import ReadonlyField from "@/components/form/fields/ReadonlyField";
import MediaLib from "@/components/medialib/MediaLib";
import { MediaUtility } from "@/lib/MediaUtility";

@Component({
  components: {
    ReadonlyField,
    MediaLib,
  },
  inject: {
    fileService: "fileService",
  },
})
export default class MediaField extends mixins(BaseField, Translations) {
  ACCEPTED_MEDIA_TYPES_DEF = {
    // generic types
    image: "mdi-image-outline",
    video: "mdi-movie-open-outline",
    audio: "mdi-music-note",
    // custom types
    background: "mdi-image",
    document: "mdi-file-document-outline",
  };
  FILE_MEDIA_TYPES_DEF = "application/*,audio/*,font/*,image/*,text/*,video/*";

  open = false;
  missingFromLib = false;
  selectedInLib = null;
  selectedFile = null;
  filename = null;
  clearingField = false;

  get ACCEPTED_MEDIA_TYPES() {
    return Object.keys(this.ACCEPTED_MEDIA_TYPES_DEF);
  }

  get hasMediaType() {
    if (
      this.field?.mediaType &&
      this.ACCEPTED_MEDIA_TYPES.includes(this.field.mediaType)
    ) {
      return true;
    }
    return false;
  }

  get mediaTypeAccept() {
    if (this.hasMediaType) {
      switch (this.field.mediaType) {
        case "image":
        case "video":
        case "audio":
          return `${this.field.mediaType}/*`;
        case "background":
          return "image/*,video/*";
        case "document":
          return "application/*,text/*";
      }
    }
    return null;
  }

  get accept() {
    return (
      this.field?.accept ?? this.mediaTypeAccept ?? this.FILE_MEDIA_TYPES_DEF
    );
  }

  get isImage() {
    return /(\.png|\.jpg|\.jpeg|\.svg|\.ico)$/gi.test(this.filename);
  }

  get selectedAtLeastOne() {
    return this.selectedInLib && this.selectedInLib.length == 0;
  }

  get fieldIcon() {
    if (this.field?.icon) {
      return this.field?.icon;
    }
    if (this.hasMediaType) {
      return this.ACCEPTED_MEDIA_TYPES_DEF[this.field.mediaType];
    }
    return "mdi-paperclip";
  }

  get libInitialFolder() {
    const selectedInLibArr = this.selectedInLib ?? [];
    return selectedInLibArr.length > 0 ? selectedInLibArr[0]?.idFolder : null;
  }

  get initiallySelected() {
    return this.selectedInLib !== null ? [this.selectedInLib] : [];
  }

  get showPreview() {
    return this.internalModel && this.filename && this.selectedFile;
  }

  updateSelected(selected) {
    this.selectedInLib = selected;
  }

  updateDblClickSelected(selected) {
    this.selectedInLib = [selected];
    this.select();
  }

  select() {
    const target = this.selectedInLib[0];
    this.internalModel = target.url;
    this.filename = target.fileName;
    this.selectedFile = this.getFileUrl(target?.publicUrl ?? target.url);
    this.open = false;
    this.missingFromLib = false;
  }

  clearField() {
    this.clearingField = true;
  }

  openMediaLib() {
    if (this.clearingField) {
      this.clearingField = false;
      this.$el.querySelector("input[type=\"text\"]").blur();
    } else {
      this.open = true;
    }
  }

  closeMediaLib() {
    this.open = false;
  }

  getFileUrl(path) {
    return MediaUtility.imgThumbSrc({ url: path });
  }

  getBaseName(fullPathName) {
    return MediaUtility.getBaseName(fullPathName);
  }

  async getFileFromUrl(url, name = null, defaultType = "image/jpeg") {
    if (url === "") {
      return null;
    }
    const response = await fetch(url);
    const data = await response.blob();
    let fileName = name ?? this.getBaseName(url);
    return new File([data], fileName, {
      type: response.headers.get("content-type") || defaultType,
    });
  }

  async created() {
    if (typeof this.internalModel === "undefined") {
      this.internalModel = null;
    }
    if (this.internalModel !== null) {
      this.selectedFile = this.getFileUrl(this.internalModel);
      let mediaLibFile = null;
      try {
        mediaLibFile = await this.fileService.readByUrl(this.internalModel);
        this.filename = mediaLibFile.fileName;
        this.selectedFile = this.getFileUrl(mediaLibFile.publicUrl);
      } catch (e) {
        this.filename = this.getBaseName(this.internalModel);
        this.missingFromLib = true;
      }
      this.selectedInLib = [mediaLibFile];
      if (this.selectedFile) {
        await fetch(this.selectedFile)
          .then((r) => {
            if (r.status != 200) {
              this.selectedFile = this.selectedFile.replace(".thumb.", ".");
            }
            // do nothing: the thumb-image is reachable
          })
          .catch(() => {
            this.selectedFile = this.selectedFile.replace(".thumb.", ".");
          });
      }
    }
  }
}
</script>

<style lang="scss">
.media-field {
  &__dialog-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
  }
  &__dialog {
    overflow-y: hidden;
    height: 100%;
    width: 100%;
  }
  &__preview {
    max-width: 100%;
  }
  &__preview-img {
    display: inline-block;
  }
}
</style>