import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    element: Object
  }
})
export default class CommonPropsUtils extends Vue {
  boolOrFunction(key) {
    if (typeof this.element[key] === "function") {
      return this.element[key];
    }
    return () => { return !!this.element[key]; };
  }

  isDisabled() {
    return this.boolOrFunction("disabled")();
  }

  isReadonly() {
    return this.boolOrFunction("readonly")();
  }

  isRequired() {
    return this.boolOrFunction("required")();
  }
}
