<template>
  <v-card class="fieldset">
    <v-toolbar v-if="fieldsetLegend" flat color="primary" dark>
      <slot :name="`fieldset-header-${structure.id}`">
        <v-toolbar-title>{{ fieldsetLegend }}</v-toolbar-title>
      </slot>
    </v-toolbar>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(panel, index) in structure.panels"
        :key="index"
      >
        <v-expansion-panel-header>
          <div>
            <v-icon v-if="panel.icon" left>{{ panel.icon }}</v-icon>
            {{ panel.header }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- nested elements -->
          <ElementsStructure
            v-if="panel.elements && panel.elements.length > 0 && groupElements === 'object'"
            :model="internalModel[panel.id]"
            :element="decorateAsElements(panel, hierarchy, 'default')">
          </ElementsStructure>
          <!-- array model -->
          <ElementsStructure
            v-else-if="panel.elements && panel.elements.length > 0 && groupElements === 'array'"
            :model="internalModel[index]"
            :element="decorateAsElements(panel, hierarchy + '/' + index)">
          </ElementsStructure>
          <!-- flat model -->
          <ElementsStructure
            v-else-if="panel.elements && panel.elements.length > 0"
            :model="internalModel"
            :element="decorateAsElements(structure, hierarchy)">
          </ElementsStructure>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import ElementsStructure from "@/components/form/structure/ElementsStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "AccordionStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class AccordionStructure extends mixins(ElementsStructure, ModelPropagate) {
  get fieldsetLegend () {
    return this.structure?.legend ?? "";
  }
}
</script>

<style lang="scss" scoped>
  /* TODO: find a better way? */
  .v-expansion-panel-header {
    font-size: 1.3rem!important;
  }
</style>
