<template>
  <v-card class="fieldset">
    <v-toolbar v-if="fieldsetLegend" flat color="primary" dark>
      <v-btn v-if="structure.collapsible" icon>
        <v-icon @click="toggleOpen">{{ this.open ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
      </v-btn>
      <slot :name="`fieldset-header-${structure.id}`">
        <v-toolbar-title>{{ fieldsetLegend }}</v-toolbar-title>
      </slot>
    </v-toolbar>
    <div v-show="open">
      <!-- nested elements -->
      <ElementsStructure
        v-if="structure.elements && structure.elements.length > 0 && groupElements === 'object'"
        :model.sync="internalModel[structure.id]"
        :element="decorateAsElements(structure, hierarchy, 'default')">
      </ElementsStructure>
      <!-- flat model -->
      <ElementsStructure
        v-else-if="structure.elements && structure.elements.length > 0"
        :model.sync="internalModel"
        :element="decorateAsElements(structure, hierarchy)">
      </ElementsStructure>
    </div>
  </v-card>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import ElementsStructure from "@/components/form/structure/ElementsStructure";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "FieldsetStructure",
  props: {
    model: null, // Any type
    element: Object
  }
})
export default class FieldsetStructure extends mixins(ElementsStructure, ModelPropagate) {
  open = false;

  get fieldsetLegend () {
    return this.structure?.legend ?? "";
  }

  toggleOpen () {
    this.open = !this.open;
  }

  created () {
    if (this.structure.collapsible) {
      this.open = this.structure?.open ?? false;
    } else {
      this.open = true;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>