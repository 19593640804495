<template>
  <validation-observer ref="observer">
    <form :id="formId" class="form-body" @submit.prevent="submit">
      <div class="form-body__toolbar">
        <v-toolbar dark>
          <v-btn icon class="hidden-xs-only" @click="goBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title>Back</v-toolbar-title>

          <ConfirmDialog ref="confirm" />
          <v-spacer></v-spacer>
          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            :color="actionColor(action)"
            class="ma-2"
            :class="action.class"
            :disabled="disabledCondition(action)"
            :loading="loadingCondition(action)"
            :title="action.title"
            :type="action.type"
            @click="emitAction(action)"
            :form="formId"
            depressed
          >
            <v-icon v-if="action.leftIcon" left>{{ action.leftIcon }}</v-icon>
            {{ action.text }}
            <v-icon v-if="action.rightIcon" right>{{
              action.rightIcon
            }}</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <v-sheet class="pa-12 form-body__wrapper" color="lighten-3">
        <v-card>
          <div v-if="errors.length > 0" class="pa-12">
            <v-alert dense type="error">
              <div v-for="(error, index) in errors" :key="index">
                {{ error }}
              </div>
            </v-alert>
          </div>

          <div class="d-flex">
            <h1 class="text-h1 text-left pl-12 pb-6 pt-6">{{ formTitle }}</h1>
            <a v-if="helpLink" :href="helpLink" target="_blank" class="pt-6 pl-2" title="See the documentation"><v-icon color="blue darken-2">mdi-help-circle</v-icon></a>
          </div>
          <v-divider inset></v-divider>

          <FormBodyRepeater
            :elements="elements"
            :model.sync="model"
            :columns="columns"
            :hierarchy="formId"
          >
          </FormBodyRepeater>

          <slot></slot>
        </v-card>
      </v-sheet>
    </form>
  </validation-observer>
</template>

<script>
import ConfirmDialog from "@/components/dom/ConfirmDialog";
import ModelPropagate from "@/mixins/ModelPropagate";
import Component, { mixins } from "vue-class-component";
import { EventBus } from "@/lib/EventBus";
import FormBodyRepeater from "@/components/form/FormBodyRepeater";
import { ValidationObserver, setInteractionMode } from "vee-validate";

@Component({
  name: "FormBody",
  components: {
    FormBodyRepeater,
    ValidationObserver,
    ConfirmDialog,
  },
  props: {
    model: null, // Any type
    formId: String,
    formTitle: String,
    actions: Array,
    elements: Array,
    errors: Array,
    columns: Number,
    helpLink: String,
  },
})
export default class FormBody extends mixins(ModelPropagate) {
  loadingCondition(item) {
    if (typeof item.loading === "function") {
      return item.loading();
    }
    return false;
  }
  actionColor(item) {
    return item?.color ?? "primary";
  }

  disabledCondition(item) {
    if (typeof item.disabled === "function") {
      return item.disabled();
    }
    if (typeof item.loading === "function") {
      return item.loading();
    }
    return false;
  }

  goBack() {
    this.$router.go(-1);
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  async emitAction(action) {
    if (action && action?.id) {
      if (action.needConfirmation) {
        if (
          await this.$refs.confirm.open(
            this.translations.confirmDialog.title,
            action.confirmationMessage
          )
        )
          this.performAction(action);
      } else this.performAction(action);
    }
  }

  async performAction(action) {
    const passesValidation = action.skipValidations
      ? true
      : await this.$refs.observer.validate();
    this.$emit("action:" + action.id, action, passesValidation);
  }

  async submit() {
    this.emitAction();
    await this.$refs.observer.validate();
  }

  clear() {
    this.$emit("reset-form");
    this.$refs.observer.reset();
  }

  setError(vid, errors) {
    errors = typeof errors === "string" ? [errors] : errors;
    let errorsObj = {};
    errorsObj[vid] = errors;
    this.$refs.observer.setErrors(errorsObj);
  }

  created() {
    setInteractionMode("eager");
    EventBus.$on("error:/" + this.formId, this.setError);
  }

  destroyed() {
    EventBus.$off("error:/" + this.formId, this.setError);
  }
}
</script>

<style lang="scss" scoped>
.form-body {
  &__field {
    padding: 5px 25px;
  }
}
</style>