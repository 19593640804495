<script>
import Tinkerable from "@/mixins/Tinkerable";
import CommonPropsUtils from "@/mixins/CommonPropsUtils";
import Component, { mixins } from "vue-class-component";

@Component({
  props: {
    element: Object
  },
  components: {
    FormBodyRepeater: () => import("@/components/form/FormBodyRepeater")
  }
})
export default class BaseStructure extends mixins(Tinkerable, CommonPropsUtils) {
  groupModel = null;
  get hierarchy () {
    return this.element?.hierarchy ?? "";
  }
  get childElements () {
    return this.objChildElements(this.element);
  }
  get elementsColumns () {
    return this.objColumns(this.element);
  }
  /**
   * Accepted values:
   *  - array
   *  - object
   *  - default
   */
  get groupElements () {
    const group = this.element?.group ?? "default";
    return [ "array", "object", "default" ].includes(group)
      ? group
      : "default";
  }

  get structure() {
    return this.tinkered(this.element);
  }

  structureKey() {
    const key = this.structure?.key
      ? this.structure.key
      // eslint-disable-next-line no-undef
      : Utility.uid("bss");
    return key;
  }

  tinkerChanged() {
    this.$recompute("structure");
  }

  objChildElements (obj) {
    return obj?.elements ?? [];
  }
  objColumns (obj) {
    return obj?.elementsColumns ?? 2;
  }
}
</script>