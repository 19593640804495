<template>
  <div>
    <v-menu
      v-if="!field.readonly"
      v-model="field.openPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <BaseField :element="element" :model="internalModel">
          <template v-slot:default="{ errors }">
            <v-text-field
              v-model="internalModel"
              :label="fieldLabel"
              :name="field.id"
              append-outer-icon="mdi-calendar"
              :hint="field.hint"
              :persistent-hint="field.persistentHint"
              :placeholder="field.placeholder"
              :disabled="field.disabled"
              :required="field.required"
              :max="maxDate"
              :min="minDate"
              v-bind="attrs"
              v-on="on"
              :error="errors.length > 0"
              :error-messages="errors"
              readonly
              :outlined="!field.readonly"
              clearable
              @click:clear="internalModel = null"
            ></v-text-field>
          </template>
        </BaseField>
      </template>
      <v-date-picker
        v-model="internalModel"
        @input="field.openPicker = false"
      ></v-date-picker>
    </v-menu>
    <ReadonlyField v-else :model="readableValue" :element="field" />
  </div>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ReadonlyField from "@/components/form/fields/ReadonlyField";
import Component from "vue-class-component";

@Component({
  components: {
    ReadonlyField,
  },
})
export default class DateField extends BaseField {
  openPicker = false;

  get readableValue() {
    return this.internalModel.substring(0, 10);
  }

  get maxDate() {
    if (!this.field?.max) {
      return null;
    }
    return new Date(this.field.max).toISOString().substr(0, 10);
  }

  get minDate() {
    if (!this.field?.min) {
      return null;
    }
    return new Date(this.field.min).toISOString().substr(0, 10);
  }
}
</script>