<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <v-slider
        v-model="internalModel"
        :error="errors.length > 0"
        :error-messages="errors"
        :step="fieldStep"
        :min="field.min"
        :max="field.max"
        thumb-label
        ticks
        type="slider"
        inverse-label
        :label="fieldLabel"
      ></v-slider>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component()
export default class SliderField extends BaseField {
  get fieldStep() {
    return this.field.step ?? 100;
  }
}
</script>