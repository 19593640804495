<template>
  <div v-if="elements && elements.length > 0" class="form-body-repeater">
    <v-container fluid class="form-body-repeater__row-container">
      <v-row>
        <v-col v-for="(element, index) in elements" :key="index" cols="12" :md="elementWidth(element)" class="form-body-repeater__field" :class="elementStyle(element)">
          <FormElement :class="formElementStyle(element)" :model.sync="internalModel" :element="decoratedElement(element)" :errors="errors" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import Component, { mixins } from "vue-class-component";
import FormElement from "@/components/form/FormElement";

@Component({
  name: "FormBodyRepeater",
  props: {
    elements: Array,
    model: null, // any
    errors: Array,
    columns: Number,
    hierarchy: {
      type: String,
      default: ""
    }
  },
  components: {
    FormElement
  }
})
export default class FormBodyRepeater extends mixins(ModelPropagate) {
  decoratedElement (element) {
    const parentHierarchy = this.hierarchy;
    const elementId = (element?.id ? "/" + element.id : "");
    const hierarchy = parentHierarchy
      + elementId;
    return {
      ...element,
      hierarchy
    };
  }

  elementWidth(element) {
    const elementColumns = element?.columns ?? this.columns;
    return element?.resetColumns
      ? 12
      : 12 / elementColumns;
  }

  formElementStyle(element) {
    const noPadding = !!element?.noPadding;
    return {
      "form-body-repeater__field--no-padding": noPadding
    };
  }

  elementStyle(element) {
    let hideCondition = () => { return false; };
    if (element?.type === "hidden") {
      hideCondition = () => { return true; };
    } else if (typeof element?.hide === "function") {
      hideCondition = element?.hide;
    }
    return {
      ...this.formElementStyle(element),
      "form-body-repeater__hidden": hideCondition()
    };
  }
}
</script>

<style lang="scss" scoped>
.form-body-repeater {
  &__field {
    padding: 5px 25px;

    &--no-padding {
      padding: 0;
    }
  }
  &__row-container {
    width: 100%;
  }
  &__hidden {
    display: none;
  }
}
</style>