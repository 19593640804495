<template>
  <v-card class="tabs">
    <v-toolbar v-if="structure.header" flat color="primary" dark>
      <slot :name="`tabs-header-${structure.id}`">
        <v-toolbar-title>{{ structure.header }}</v-toolbar-title>
      </slot>
    </v-toolbar>
    <v-tabs v-model="selectedTab" :vertical="structure.vertical" flat color="primary">
      <v-tab
        v-for="tab in structure.tabs"
        :key="tab.id"
        :href="`#tab-${tab.id}`"
        centered
        icons-and-text
      >
        <slot :name="`tab-label-${tab.id}`" v-bind:tab="tab">
          <v-icon v-if="tab.leftIcon" left>{{ tab.leftIcon }}</v-icon>
          {{ tab.label }}
          <v-icon v-if="tab.rightIcon" right>{{ tab.rightIcon }}</v-icon>
        </slot>
      </v-tab>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item
          v-for="(tab, index) in structure.tabs"
          :key="tab.id"
          :value="`tab-${tab.id}`"
          :vertical="structure.vertical"
        >
          <!-- tabs fields saved in plain array -->
          <ElementsStructure
            v-if="groupElements === 'array'"
            :model="internalModel[index]"
            :element="decorateAsElements(structure.tabs[index], hierarchy + '/' + index)">
          </ElementsStructure>
          <!-- otherwise -->
          <ElementsStructure
            v-else
            :model="internalModel"
            :element="decorateAsElements(structure.tabs[index], hierarchy, 'default')">
          </ElementsStructure>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import ModelPropagate from "@/mixins/ModelPropagate";
import ElementsStructure from "@/components/form/structure/ElementsStructure";
import Component, { mixins } from "vue-class-component";
import FieldsetStructure from "@/components/form/structure/FieldsetStructure";

@Component({
  name: "TabsStructure",
  props: {
    model: null, // Any type
    element: Object
  },
  components: {
    FieldsetStructure
  },
  watch: {
    selectedTab () {
      // if the whole tabs is readonly, propagate it to child components on tab change
      if (this.structure.readonly) {
        this.setReadonly(true);
      }
    }
  }
})
export default class TabsStructure extends mixins(ElementsStructure, ModelPropagate) {
  selectedTab = null;

  get structure () {
    return this.tinkered(this.element);
  }

  tabHierarchy (tab, index) {
    const tabModel = this.groupElements;
    const hierarchy = this.hierarchy;
    switch (tabModel) {
      case "array":
        return hierarchy + "/" + index;
      case "object":
        return hierarchy + "/" + tab.id;
      default:
        return hierarchy;
    }
  }

  created () {
    if (this.structure?.tabs && this.structure.tabs.length > 0) {
      this.selectedTab = this.structure.tabs[0].id;
    }
    if (this.groupElements === "array" && this.internalModel.length == 0) {
      this.structure.tabs.forEach(() => this.internalModel.push({}));
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldset {
  border-radius: 25px;
  width: 100%;
}
</style>