<!-- eslint-disable vue/no-unused-vars -->
<template>
  <BaseField :element="element" :model="internalModel">
    <template v-slot:default="{ errors }">
      <div class="color-field">
        <label class="color-field__label">{{ fieldLabel }}</label>
        <div class="color-field__input-container">
          <label class="color-field__preview" :style="`opacity:${alpha};background-color:${color}`">
            <input
              class="color-field__input"
              type="color"
              v-model="color"
              :name="field.id"
              :label="fieldLabel"
              :disabled="field.disabled"
              :required="field.required"
            />
          </label>
        </div>
        <div class="color-field__alpha-container">
          <v-slider
            v-if="mode === 'rgba'"
            v-model="alpha"
            step="0.05"
            min="0"
            max="1"
            thumb-label
            ticks
            type="slider"
            inverse-label
            prepend-icon="mdi-opacity"
          ></v-slider>
        </div>
      </div>
    </template>
  </BaseField>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import Component from "vue-class-component";

@Component({
  watch: {
    color: function(n) {
      if (this.mode === "rgba") {
        this.internalModel = this.formatRgba();
      } else if (this.mode === "rgb") {
        this.internalModel = this.formatRgb();
      } else {
        this.internalModel = n;
      }
    },
    alpha: function(n) {
      if (this.mode === "rgba") {
        this.internalModel = this.formatRgba();
      } else if (this.mode === "rgb") {
        this.internalModel = this.formatRgb();
      } else {
        this.internalModel = n;
      }
    },
  }
})
export default class ColorField extends BaseField {
  color = null;
  alpha = 1;

  get mode () {
    return this.field?.mode ?? "hex";
  }

  isHex(val) {
    return val.startsWith("#");
  }

  isRgb(val) {
    return val.startsWith("rgb(");
  }

  isRgba(val) {
    return val.startsWith("rgba(");
  }

  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  rgbaToHex(rgba) {
    rgba = rgba.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgba && rgba.length === 4
      ? "#" +
          ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
      : "";
  }

  rgbaToAlpha(rgba) {
    rgba = rgba.split(",");
    let alpha = rgba[rgba.length - 1];
    alpha = alpha.replace(/\)$/, "").trim();
    const val = parseFloat(alpha);
    if (val > 1) {
      return 1;
    }
    if (val < 0) {
      return 0;
    }
    return val;
  }

  rgbToHex(rgb) {
    rgb = rgb.match(
      /^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4
      ? "#" +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : "";
  }

  formatRgb() {
    const val = this.hexToRgb(this.color);
    return `rgb(${val.r}, ${val.g}, ${val.b})`;
  }

  formatRgba() {
    const val = this.hexToRgb(this.color);
    return `rgba(${val.r}, ${val.g}, ${val.b}, ${this.alpha})`;
  }

  created () {
    let hexValue;
    if (this.isHex(this.internalModel)) {
      hexValue = this.internalModel;
    } else if (this.isRgba(this.internalModel)) {
      hexValue = this.rgbaToHex(this.internalModel);
    } else if (this.isRgb(this.internalModel)) {
      hexValue = this.rgbToHex(this.internalModel);
    }
    this.color = hexValue;
    if (this.mode === "rgba") {
      this.alpha = this.rgbaToAlpha(this.internalModel);
    }
  }
}
</script>

<style lang="scss" scoped>
.color-field {
  text-align: left;

  &__input-container {
    position: relative;
    display: block;
    text-align: left;
    height: 60px;
    width: 60px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;
    border: 1px solid #777777;
  }

  &__alpha-container {
    display: block;
    max-width: 80%;
    min-width: 200px;
    width: 200px;
  }

  &__label {
    display: block;
    font-size: 16px;
    font-weight: 700;
  }

  &__input {
    visibility: hidden;
  }

  &__preview {
    position: absolute;
    display: block;
    height: 58px;
    width: 58px;
  }
}
</style>