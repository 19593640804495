<template>
  <div class="select-field">
    <BaseField :element="element" :model="internalModel">
      <template v-slot:default="{ errors }">
        <v-select
          v-model="internalModel"
          :name="field.id"
          :multiple="field.multiple"
          :chips="field.multiple"
          :label="fieldLabel"
          :hint="field.hint"
          :persistent-hint="field.persistentHint"
          :placeholder="field.placeholder"
          :disabled="field.disabled"
          :readonly="field.readonly"
          :required="field.required"
          :items="fieldItems"
          item-text="label"
          item-value="value"
          :error="errors.length > 0"
          :error-messages="errors"
          :return-object="field.returnObject"
          v-on:change="on_action($event)"
          :clearable="field.clearable"
          outlined
        ></v-select>
      </template>
    </BaseField>
  </div>
</template>

<script>
import BaseField from "@/components/form/fields/BaseField";
import ReadonlyField from "@/components/form/fields/ReadonlyField";
import Component from "vue-class-component";

@Component({
  components: {
    ReadonlyField,
  },
  watch: {
    internalModel: function (newVal) {
      if (typeof this.field?.onChange === "function") {
        this.field.onChange(newVal);
      }
    },
  },
})
export default class SelectField extends BaseField {
  get readableValue() {
    const modelRef = Array.isArray(this.model) ? this.model : [this.model];
    const target = this.field.items.filter((i) => modelRef.includes(i.value));
    if (this.field.multiple) {
      return target;
    }
    return target.length > 0 ? target[0].label : this.model;
  }

  get fieldItems() {
    this.field?.items?.sort((a, b) => {
      const aGroup = a?.group;
      const bGroup = b?.group;
      if (aGroup == bGroup) {
        return 0;
      }
      return aGroup < bGroup ? -1 : 1;
    });
    let lastGroup = null;
    let groupedItems = [];
    for (let i in this.field.items) {
      let item = this.field.items[i];
      if (item?.group && lastGroup !== item?.group) {
        groupedItems.push({
          header: item.group,
        });
        lastGroup = item.group;
      }
      groupedItems.push(item);
    }
    return groupedItems;
  }

  on_action(event) {
    if (
      this.field.onAction &&
      this.field.onAction &&
      typeof this.field.onAction === "function"
    )
      this.field.onAction(event);
  }
}
</script>

<style lang="scss">
/* TODO: find a better way? */
.v-list-item__title {
  text-align: left;
  font-size: 1.3rem !important;
}
.v-select-list .v-subheader {
  height: 24px;
  padding-left: 8px;
}
</style>